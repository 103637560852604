
export function extractDomainName(url) {
  let domain = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im)[1];
  return domain.split('.')[0];
}

export function extractDomainURL(url) {
  let domainURL = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im)[1];
  return domainURL;
}

export function isClapswapFlow() {
  const url = new URL(window.location.href).origin;
  return url.includes('clapswap') || url.includes('gift2money') || !url.includes('swap4gifts')
}

export function isClapswapDomain() {
  const url = new URL(window.location.href).origin;
  if (url.includes('clapswap')) {
    return true
  } else if (url.includes('gift2money')) {
    return false
  } else {
    return null
  }
}
