import { createAction, createReducer } from '@reduxjs/toolkit'
import frc from 'front-end-common'
import SubmitCodesService from '../services/SubmitCodesService'

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const currencySwitch = createAction('submitCodes/setCurrency');

export const platformGroupsFetch = createAction('submitCodes/platformsList', () => ({
  payload: SubmitCodesService.fetchPlatformGroups(),
}))

export const postNewCodes = createAction(
  'submitCodes/postCodes',
  (platform: string, group: string, codes: string[], notes: any, customRate: number) => ({
    payload: SubmitCodesService.postCodes(platform, group, codes, notes, customRate),
  })
)

const initState = {
  isCurrencySwitched: false,
  platformsList: getDefaultState(),
  postCodes: getDefaultState(),
}

export const submitCodesReducer = createReducer(initState, {

  //@ts-ignore
  [currencySwitch]: (state, action) => ({
    ...state,
    isCurrencySwitched: action.payload,
  }),
  ...getDefaultHandler(platformGroupsFetch, 'platformsList'),
  ...getDefaultHandler(postNewCodes, 'postCodes'),
})

export default submitCodesReducer
