import React, { useEffect, useState } from 'react'
import { Typography, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { UserState } from '../../types/rootState'
import useMediaQuery from '../../utils/useMediaQuery'

const { Text } = Typography

interface ListType {
  currency: string
  balanceActual: string
  balancePending: string
  usdRate: string
  usdBalanceActual: string
  usdBalancePending: string
}

type UserWallet = UserState['user']['wallet']

const MyWalletTable: React.FC<UserWallet> = ({ payload, isLoading }) => {
  
  const media500 = useMediaQuery('(max-width: 500px)');

  // const balance = localStorage.getItem('balance')
  // const currency = balance !== null && JSON.parse(balance)?.currency;
  const currency = payload?.currency

  function GetColumns(): ColumnsType<ListType> {
    
    return payload ? [
      {
        dataIndex: 'currency',
        title: 'CURRENCY',
        align: 'left',
        width: 109,
      },
      {
        dataIndex: 'balanceActual',
        title: 'BALANCE',
        align: 'left',
        width: 100,
        render: (balanceActual, record) => (
          <span>
            <b>{balanceActual}</b> ({record.balancePending})
          </span>
        ),

      },
      {
        dataIndex: 'usdRate',
        title: `${currency} RATE`,
        align: 'left',
        width: 119,
      },
      {
        dataIndex: 'usdBalanceActual',
        title: `BALANCE ${currency}`,
        align: 'left',
        width: 139,
        render: (usdBalanceActual, record) => (
          <span>
            <b>{usdBalanceActual}</b> ({record.usdBalancePending})
          </span>
        ),
      },
    ] : []
  }

  function GetMobileColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'currency',
        title: `BALANCE/${currency} RATE`,
        align: 'left',
        width: 150,
        render: (currency, record) => (
          <>
            <b>{record.balanceActual} </b> ({record.balancePending})
            {' '}<span>{currency}</span>
            <br />
            <span>
              <b>{record.usdRate}</b> USD
            </span>
          </>
        ),
      },
      {
        dataIndex: 'usdBalanceActual',
        title: `BALANCE ${currency}`,
        align: 'left',
        width: 139,
        render: (usdBalanceActual, record) => (
          <span>
            <b>{usdBalanceActual}</b> ({record.usdBalancePending})
          </span>
        ),
      },
    ]
  }

  const [columns, setColumns] = useState(GetColumns())

  useEffect(() => {
    media500 ? setColumns(GetMobileColumns()) : setColumns(GetColumns())
  }, [payload, media500])

  return (
    <Table
      className="myWalletTable"
      size={media500 ? 'small' : 'large'}
      rowKey={(record) => record.currency}
      dataSource={payload?.currencies}
      columns={columns}
      loading={isLoading}
      style={{ width: '600px' }}
      // scroll={{ y: 'calc(100vh - 125px)', x: 'max-content' }}
      pagination={false}
      summary={(pageData) => {
        if (media500) {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} align="right">
                  <span>Total:</span>
                  <br />
                  <span className="greySpan">* - pending balance</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="left">
                  <Text strong>{payload?.usdTotalActual}</Text>
                  <Text> ({payload?.usdTotalPending})*</Text>
                  <br />
                  <span>&nbsp;</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        } else
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={2} align="left">
                  <span className="greySpan">* - pending balance</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  Total:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center">
                  <Text strong>{payload?.usdTotalActual}</Text>
                  <Text> ({payload?.usdTotalPending})*</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
      }}
    />
  )
}

export default MyWalletTable
