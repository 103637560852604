import React from 'react'
import styles from './styles.module.scss'

const CustomImage = ( {src, srcSet, alt, className, width, height } ) => {

  return (
      <picture>
          <source srcSet={srcSet} type="image/webp" width={width} height={height} />
          <img src={src} alt={alt} title={alt} width={width} height={height} className={styles.logo} loading='lazy' />
      </picture>
)};

export default React.memo(CustomImage);
