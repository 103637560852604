import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import SignInPage from '../../pages/login';

function RedirectUserToMainPage() {
  let location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);
  // const isAuthenticated = false

  // console.log('isAuthenticated', isAuthenticated)
  if (location.pathname === '/sign-out') {
    location.pathname = '/';
  }
  return !isAuthenticated ? <SignInPage /> : <Navigate to={location.pathname || '/submit-codes'} />
}

export default RedirectUserToMainPage;
