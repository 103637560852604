export default function convertPlatforms(obj) {
  let newObj = {};
  let newerObj = {};
  if (obj && typeof obj === 'object') {
    Object.entries(obj).map(el => {
      if (el[0].slice(0, 2) !== 'R-') {
        Object.assign(newObj, { [el[0]]: { [el[0]]: el[1] } })
      } if (el[0].slice(0, 2) === 'R-') {
        Object.assign(newerObj, { [el[0].slice(2)]: { [el[0]]: el[1] } })
      }
    })

    Object.keys(newObj).forEach(key => {
      if (!newerObj[key]) newerObj[key] = newObj[key]
      if (newerObj[key]) Object.assign(newerObj[key], newObj[key])
    })
    return newerObj
  }
}
