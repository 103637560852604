import ApiService from './ApiService'

const SubmitCodesService = {
  fetchPlatformGroups() {
    return ApiService.get('/codes/submit')
  },

  postCodes(platform: string, group: string, codes: string[], notes: any, customRate: number) {
    return ApiService.post('/codes/submit', {
      platform: platform,
      group: group,
      codes: codes,
      notes: notes,
      customRate: customRate,
    })
  },
}

export default SubmitCodesService
