import { useMemo, useCallback } from 'react'
import { parseQueryString, prepareQueryString } from '../../services/ApiService'
import { useLocation, useNavigate } from 'react-router-dom'

const EMPTY_FILTERS = {}
const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100', '200', '500']

const TableFeatures = () => {

  const location = useLocation();
  const navigate = useNavigate();


  const params = useMemo(
    () => ({ limit: 50, offset: 0, ...parseQueryString(location.search) }),
    [location.search],
  );

  const pagination = useCallback(
    (total: number) => {
      return {
        total,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        showSizeChanger: true,
        current: Math.round((params.offset || 0) / (params.limit || 50) + 1),
        pageSize: parseInt(params.limit, 10) || 50,
      }
    },
    [params.offset, params.limit]
  )

  const filters = useMemo(() => params.filters || EMPTY_FILTERS, [params.filters])

  const query = useMemo(() => params.query || '', [params.query])

  const onChange = useCallback(
    (
      { pageSize, current }: { pageSize: number; current: number },
      newTableFilters: {},
      { field, order }: { field: any; order: any }
    ) => {
      const limit = pageSize
      let offset = (current - 1) * pageSize

      if (params.offset === offset) {
        offset = 0
      }

      const orderBy = field && order ? field : null
      const orderPath = (orderBy && order) || null

      const curFilters = { ...filters, ...newTableFilters }
      const newFilters = Object.keys(curFilters)
        .filter((key) => curFilters[key] !== null)
        .reduce((acc, cur) => ({ ...acc, [cur]: curFilters[cur] }), {})

        navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          limit,
          filters: newFilters,
          offset,
          orderBy,
          orderPath,
        }),
      })
    },
    [location.pathname, filters, params, navigate]
  )

  const onFilter = useCallback(
    (newTableFilters: {}) => {
      const curFilters = { ...filters, ...newTableFilters }
      const newFilters = Object.keys(curFilters)
        .filter((key) => curFilters[key] !== null)
        .reduce((acc, cur) => ({ ...acc, [cur]: curFilters[cur] }), {})

      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          offset: 0,
          filters: newFilters,
        }),
      })
    },
    [filters, navigate, params, location.pathname]
  )

  const onSearch = useCallback(
    (query = '') => {
      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          offset: 0,
          query: query.trim() ? query : undefined,
        }),
      })
    },
    [navigate, params, location.pathname]
  )

  return {
    params,
    pagination,
    onChange,
    filters,
    query,
    onSearch,
    onFilter,
  }
}

export default TableFeatures
