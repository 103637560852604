import React from 'react'
import styles from './styles.module.scss'
// import {signIn, signOut, useSession} from 'next-auth/react'
import { shallowEqual, useDispatch } from 'react-redux'
import { authSetIsAuthenticated, authSignOut } from '../../reducers/auth'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function SignIn({ title = 'Log in', textColor}) {
  // const {data: session} = useSession()

  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  const dispatch = useDispatch()

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(authSignOut())

    dispatch(authSetIsAuthenticated(false))
    navigate('/')
  }

  return (
    <>
      
      {!isAuthenticated ? (
          <a
            // onClick={(e) => {
            //   e.preventDefault()
            //   signIn()
            // }}
            href="/sign-in"
            style={{color: textColor}}
            className={styles.signin}
          >
           {title}
          </a>
      ) : (
          <a
            onClick={handleLogout}
            className={styles.signin}
          >
            Sign out
          </a>
      )}
    </>
  )
}

export default SignIn
