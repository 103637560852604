import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MyCodesState from '../../types/rootState'
import { Typography } from 'antd'
import { getGroups, myCodesFetch } from '../../reducers/myCodes'
import MyCodesTable from './MyCodesTable'
import TableFeatures from '../../components/TableFeatures'

const { Title } = Typography

const MyCodes = () => {
  const { params, pagination, onChange, filters } = TableFeatures()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(myCodesFetch(params))
    dispatch(getGroups())
  }, [dispatch, params])

  const { payload, isLoading } = useSelector((state: MyCodesState) => state?.myCodes?.codesList) || {}
  const groupsData = useSelector((state) => state?.myCodes?.groups) || {}

  const { list, total } = useMemo(
    () => ({
      list: (payload && payload.list) || [],
      total: (payload && payload.total) || [],
    }),
    [payload]
  )

  return (
    <div style={{width: '85vw', maxWidth: '1280px', margin: '24px auto'}}>
      <Title level={1}>Codes</Title>

      <MyCodesTable pagination={pagination(total)} list={list} isLoading={isLoading} onChange={onChange} filters={filters} groupsData={groupsData} />
    </div>
  )
}

export default MyCodes
