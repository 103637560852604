import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, Select, message, Typography, Modal, Checkbox, Result, Spin } from 'antd';
import { loginUser, registerUser } from '../../reducers/user';
//@ts-ignore
import styles from './styles.module.scss';
import { EyeInvisibleOutlined, EyeTwoTone, PhoneOutlined, RightOutlined } from '@ant-design/icons';
import FloatingLabel from '../FloatingLabel';
import countries from './countries.json';
import SignIn from '../SignIn';
import { messageError, messageSuccess } from '../Message';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSetIsAuthenticated } from '../../reducers/auth';
import Cookies from 'js-cookie';
import { getDomainUrl } from 'front-end-common/src/utils/envDependent'
import { extractDomainName, isClapswapFlow } from '../../utils/extractDomain';
import NotAvailable from '../NotAvailable';

const { Option } = Select;
const { Title } = Typography

const SignupComponent = () => {

  const { payload, isLoading } = useSelector((state) => state?.noAuthReducer?.contacts, shallowEqual)

  const freeze = payload?.freeze && payload?.freeze;

  const navigate = useNavigate()

  const [form] = Form.useForm();
  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userAgree, setUserAgree] = useState(true);
  const [referrer, setReferrer] = useState(null);
  const [isPasswordLong, setIsPasswordLong] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [utmData, setUtmData] = useState({});

  const referrer_url = localStorage.getItem('referrer_url');

  useEffect(() => {
          const utmSource = localStorage.getItem('utmSource');
          const utmCampaign = localStorage.getItem('utmCampaign');
          const utmContent = localStorage.getItem('utmContent');
          const utmTerm = localStorage.getItem('utmTerm');

          setUtmData({utmSource, utmCampaign, utmContent, utmTerm})
    }, [])

    useEffect(() => {
      setReferrer(referrer_url)
    }, [])

  const dispatch = useDispatch()

  const onFinish = () => {
    
    setIsProcessing(true);
    dispatch(registerUser(email, password, userAgree, utmData, referrer))
    .then((res) => {
      if (!res.error) {
        // form.resetFields()
        window.dataLayer.push({ event: 'registration'})
        if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
          window.fbq('track', 'CompleteRegistration');
        }
        localStorage.removeItem('referrer_url')
      Modal.success({
        title: 'Thank you!',
        content: isClapswapFlow() ? `You're almost there! We sent an email to ${email}. Just click on the link in that email to complete signup!` : 'Registration successful!',
        okText: (
          // <span style={{ color: 'white' }}>Log In</span>
          <span style={{ color: 'white' }}>Proceed</span>
        ),
        okButtonProps: {
          style: { width: '100%', borderRadius: '4px', color: 'white', transition: 'all 0.7s ease-out', background: 'red !important'},
        },
        onOk: () => {
          navigate('/')
        }
        });
      }
    })
    .catch((error) => {
      messageError(`Failed to sign up: ${error.message || error}`)
      // console.log('error', error)
      // router.push('/')
    })
    .finally(() => {
      setIsProcessing(false);
    });
    
  };


  const handleChangeEmail = useCallback(( { target: { value } }:any) => {
    setEmail(value)
  }, [])

  const handleAgreePromo = useCallback((e) => {
    setUserAgree(e.target.checked)
  }, [])

  const handleChangeReferral = useCallback(( { target: { value } }:any) => {
    setReferrer(value)
  }, [])

  const handleChangePassword = useCallback(( { target: { value } }:any) => {
    setPassword(value)
    if(value.length >= 8) {
      setIsPasswordLong(true)
    } else {
      setIsPasswordLong(false)
    }
    if (value !== value.toLowerCase()) {
      setIsUpperCase(true)
    } else {
      setIsUpperCase(false)
    }
  }, [])


  return (
    <section className={styles.registerForm}>
    {isLoading ? (
      <Spin style={{ margin: '25px' }} />
    ) : (
    !freeze ?
    <div>
      <div className={styles.header}>
      <h1>Registration</h1>
      <SignIn />
      </div>
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      scrollToFirstError
      className={styles.signupWrapper}
    >
      <Form.Item
        name="email"
        // label="Email"
        className={styles.inputEmailWrapper}
        rules={[
          { required: true, message: 'Please enter your email' },
          // { validator: validateEmail }
        ]}
      >
        <FloatingLabel
          label="Email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={handleChangeEmail}
          />
      </Form.Item>

      <Form.Item
          name="newPassword"
          className={styles.inputPasswordWrapper}
          rules={[
            { required: true, message: 'Please enter a password' },
            {
              pattern: /^(?=.*[A-Z]).{8,}$/,
              message: 'Enter valid password!',
            },
          ]}
        >
          
        <Input.Password
          
          placeholder={'Set password'}
          value={password}
          onChange={handleChangePassword}
          // style={{fontWeight: 'bold'}}
          className={styles.registerInput}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          
        </Form.Item>

  

      <Form.Item
        name="confirm"
        // label="Repeat a new password"
        className={styles.inputPasswordWrapper}
        dependencies={['newPassword']}
        // hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords mismatch!'));
            },
          }),
        ]}
      >
        <Input.Password 
        placeholder={'Repeat password'}
        style={{fontWeight: 'bold'}}
        className={styles.registerInput}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
        
        <div className={styles.textValidated} style={isUpperCase && isPasswordLong ? {color: 'black'} : {}}>Password must have 8 characters and contain 1 uppercase letter</div>

      <Form.Item
        name="referrer"
        className={styles.inputEmailWrapper}
        rules={[
          // { required: true, message: 'Please enter your phone number' },
          { pattern: /^8\d{6}$/g, message: 'Please enter a valid referrer' },
        ]}
      >
        <FloatingLabel
          label="Referral ID"
          placeholder="Enter your referral ID if you have it"
          name="referral"
          value={referrer_url ? referrer_url : referrer}
          defaultValue={referrer_url ? referrer_url : referrer}
          onChange={handleChangeReferral}
          />
      </Form.Item>

      <div className={styles.text}><Checkbox className={styles.checkbox} onChange={handleAgreePromo} defaultChecked /> I agree to receive {extractDomainName(getDomainUrl())} updates and promo</div>
      <Form.Item >
      {!isProcessing ? (
        <div className={styles.footer}>
      <Button
        aria-label="Sign Up button"
        htmlType="submit"
        type="primary"
        className={styles.button}
        >
          Create account
        </Button>
       <a href={`/`}>Need help with registration?</a>
        </div>
        ) : (
          <Button className={styles.buttonAnimated} type="primary">
            <div className={styles.loadingiospinnerellipsisxxraaph2hu9}>
              <div className={styles.ldio4nb5p2ohc8k}>
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            </div>
          </Button>
            )}
            </Form.Item>
    </Form>
    </div> : 
    <NotAvailable /> )}
    </section>
  );
};

export default SignupComponent;
