export default function dataToFilters(obj, set) {
  const objToValues = set(obj);
  const uniqueValuesSet = new Set();

  return objToValues.reduce((result, el) => {
    const obj = { "text": el, "value": el };

    if (!uniqueValuesSet.has(el)) {
      uniqueValuesSet.add(el);
      result.push(obj);
    }

    return result;
  }, []);
}
