import React, { useEffect } from 'react';
import qs from 'qs';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { authGetTokens } from '../../reducers/user';
import TokenService from '../../services/TokenService';

function RedirectPage() {
  const location = useLocation();
  const query = qs.parse(location.search.replace(/^\?/, ''));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log('query', query)

  useEffect(() => {
    if (TokenService.getToken() !== null) {
          navigate('/submit-codes', { replace: true })
    } else {
      navigate('/')
    }
  }, [dispatch, navigate, query.code]);

  if (!TokenService.getToken()) {
    return <Navigate to="/sign-in" />;
  }
  return <div>Wait for save token</div>;
}

export default RedirectPage;
