import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { Menu } from 'antd'
import SignIn from '../SignIn'
import Balance from '../Balance'
import UserCurrencySwitcher from '../UserCurrencySwitcher'
import { UserOutlined } from '@ant-design/icons'
import { UserIconProfile } from '../SvgSprite'

function UserProfile({isAuthenticated, isLoading, setIsLoading, parsedBalance}) {

  const [openKeys, setOpenKeys] = useState([]);
  
  const menuRef = useRef(null);

  // Функция для закрытия меню пользователя - очищаем массив ключей
  const handleMenuClose = () => {
    setOpenKeys([]);
    // console.log('body clicked')
  };


  // console.log('openKeys', openKeys);


  useEffect(() => {
    // Добавить возможность закрывать меню по клику в любом месте окна
    // ивент клика создается как только 
    if (openKeys.length > 0) setTimeout(() => { document.addEventListener("click", handleMenuClose) }, 1000)
  }, [openKeys.length])


  const title = (
    <div className={styles.userProfile}>
      {/* <div className={`${styles.header__logo} ${styles.logo}`}></div> */}
            
      {isAuthenticated ? (
        <>
          <div
            className={styles.header__balance}>
                <img src='/user_icon.svg' style={{height: '50px'}} alt='user_icon' />
            </div>
            
        </>
      ) : (
        <div className={styles.userProfile__authBlock}>
          <div
            className={styles.header__log_in}>
                <img src='/user_icon.svg' alt='user_icon' />
                <a href="/signup">Register</a>
            </div>  
          <SignIn />
        </div>
      )}
    </div>
  )

  const items = [
    {
      label: title,
      key: 'userProfile',
      children: [
        {
          label: (
              <a href="/usr-settings" className={styles.link} onClick={handleMenuClose}>
                User Settings
              </a>
          ),
          key: 'usr_settings',
        },
        {
          label: <SignIn />,
          key: 'Sign',
        },
      ],
    },
  ]

  

  return (
    <div className={styles.userProfileWrapper}>
      {isAuthenticated ? <>
      {/* <UserCurrencySwitcher isLoading={isLoading} setIsLoading={setIsLoading} payload={parsedBalance} /> */}
      <Menu mode="horizontal" items={items} /><Balance payload={parsedBalance} isLoading={isLoading}/></> : title}
    </div>
  )
}

export default UserProfile
