import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Result, message } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../reducers/user';

const VerifyPage = () => {

  // const location = useLocation();

  const { search } = useLocation();
  const dispatch = useDispatch();
  const [resultVerify, setResultVerify] = useState('');
  const queryParams = new URLSearchParams(search);

  const userId = queryParams.get('userId');
  const code =  queryParams.get('code');

  // console.log(resultVerify)

  useEffect(() => {
    // if (userId && code) {
      dispatch(verifyUser(+userId, code))
      .then((res) => {
        // console.log('res', res)
        setResultVerify('success')
      })
      .catch((error) => {
        message.error(error)
        setResultVerify('failure')
      })
    // }
    
  }, [])

  // console.log('params', userId, code)
  return (
    
    
    <div className={styles.verifyLayout}>
      <Result
        status={resultVerify === "success" ? "success" : resultVerify === "failure" ? "error" : "warning"}
        title={resultVerify === "success" ? "Successfully Verified email!" : resultVerify === "failure" ? "Failed to verify. Please, try again!" : "Wait for verification"}
        extra={[
          <a className={styles.verifyLayout__button} href='/sign-in'>
            Log in
          </a>
    ]}
  />
    </div>
  )
}

export default VerifyPage
