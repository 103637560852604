import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Form, Input, InputNumber, Radio, RadioChangeEvent, Skeleton, Spin, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { messageError } from '../Message'
import { postNewCodes } from '../../reducers/submitCodes'
//@ts-ignore
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useCurrencyState from '../../utils/useCurrencyState'
import { useSelector } from 'react-redux'
import { myBalanceFetch } from '../../reducers/user'
// import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';

export interface SubmitFormData {
  platform: string
  group: string
  codes: string
  notes: string | any,
  customRate: number
}

export interface FormProps {
  item: [string, {
    [key: string]: [string]
  }],
  isLoading: boolean,
  curPlarformValue: string,
  showRate: boolean,
  ngnRate: number | undefined,
  usdRates: {
    [key: string]: number 
  } | undefined,
  ngnRates: {
    [key: string]: number 
  } | undefined
  countryCurrencies: any,
  instructions: string,
  currency: string,
  codeFormatTypes: {
    "Instant": string,
    "Non-Instant": string
  },
}

const SubmitForm: React.FC<FormProps> = ({item, curPlarformValue, isLoading, showRate, ngnRate, usdRates, ngnRates, countryCurrencies, instructions, currency, codeFormatTypes}) => {
  const dispatch: any = useDispatch();
  // const [currency, setCurrency] = useState('');

  const navigate = useNavigate();

  const balance = localStorage.getItem('balance')
  // const currency = balance !== null && JSON.parse(balance)?.currency

  // console.log('balanceSubmit', balance)
  // console.log('CURRENCYSUBMIT', currency)
  // console.log('storedCurrency', storedCurrency)

  const c = useSelector((state) => state?.submitCodes?.isCurrencySwitched) || {}

  // console.log('STATECHANGING', c)
  

  const [curPlatformValue, setCurPlatformValue] = useState<any>('');
  const [curCountryValue, setCurCountryValue] = useState('');
  const [curRate, setCurRate] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [amount, setAmount] = useState(100);
  const [isPlatformValid, setIsPlatformValid] = useState(true);
  const [debounceBalance, setDebounceBalance] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false)
  // const [currency, setCurrency] = useState('')

  // useEffect(() => {

  //   if (balance !== null) {
  //     const balanceParsed = JSON.parse(balance)
  //     setCurrency(balanceParsed?.currency)
  //   }
    
  // }, [balance])

  // console.log('BALCUR', balance, currency)

  let platformTitle = item[0]
  let platformsList = item[1]

  let options = Object.keys(platformsList)

  const handleChangeFilter = useCallback((e: RadioChangeEvent) => {
    const [curCountryValue, curRate] = e.target.value.split('-')
    setCurCountryValue(curCountryValue)
    setCurRate(curRate)
  }, [])

  const currenciesList = useMemo(
    () => platformsList && curPlatformValue && platformsList?.[curPlatformValue],
    [curPlatformValue, platformsList]
  )

  const currentUsdRate = useMemo(
    () => currency === 'USD' ?

     curPlarformValue === 'Steam' ?

     usdRates && usdRates?.['USD']

     : curCountryValue.length === 3 ? 
     usdRates?.[curCountryValue] 
     : usdRates?.[countryCurrencies[curCountryValue]] 

     : curPlarformValue === 'Steam' ?

     ngnRates && ngnRates?.['USD']

     : curCountryValue.length === 3 ? 
     ngnRates?.[curCountryValue] 
     : ngnRates?.[countryCurrencies[curCountryValue]],
    [usdRates, ngnRates, curPlarformValue, curCountryValue, currency, countryCurrencies]
  )

  const ngnToUsd = useMemo(
    () => ngnRates && ngnRates?.['USD'],
    [ngnRates]
  )

  const sortedCurrencies = currenciesList && [
    ...currenciesList.filter((item) => item.group === 'USD' || item.group === 'EUR'),
    ...currenciesList.filter((item) => item.group !== 'USD' && item.group !== 'EUR'),
  ].sort((a, b) => a.groupDisabled - b.groupDisabled);

  const defaultCurrency = currenciesList && currenciesList[0]?.group
  
  useEffect(() => {
    defaultCurrency && setCurCountryValue(defaultCurrency)
  }, [defaultCurrency])

  useEffect(() => {
    if (currenciesList.length === 1) {
      const currencyDisabled = currenciesList[0].groupDisabled
      if (currencyDisabled) setButtonDisabled(true)
    }
  }, [currenciesList])

  const defaultOption = options && options[1] ? options[1] : options[0]
  
  useEffect(() => {
    defaultOption && setCurPlatformValue(defaultOption)
  }, [defaultOption])

  const handleChangePlatform = useCallback(( { target: { value } }: any) => {
    setCurPlatformValue(value)
    // setCurCurrencies(currenciesList)
  }, [setCurPlatformValue])

  const handleSuccess = useCallback(
    (data: SubmitFormData) => {
      setIsProcessing(true)
      // console.log("SUBMITDATA", data)
      const { codes, notes = "", customRate } = data
      const codesArray: string[] = codes.split('\n')
                                   .map((item: string) => item.trim())
                                   .filter((item: string) => item !== "");

      dispatch(postNewCodes(curPlatformValue, curCountryValue, codesArray, notes, customRate / 100))
        .then((res: any) => {
          const result = res
          
          if (result.error) {
            messageError(result.error)
            setIsProcessing(false)
          } else {
          //@ts-ignore
              window.dataLayer.push({ event: 'Submit_complete'})
              if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
                window.fbq('track', 'Lead');
              }
              dispatch(myBalanceFetch())
                .then((res) => {
                  localStorage.setItem('balance', JSON.stringify(res))
                })
                .catch((err) => {
                  messageError(err)
                })
              !result.error && setTimeout(() => { 
              navigate(`/my-codes/${result.codePackId}`, { replace: true })
              setIsProcessing(false)
              }, 3000)
          }
        })
        .catch((error: any) => {
          // console.log(error)
          messageError(`Can't submit codes. ${error.message || error}`)
          setIsProcessing(false)
        })
    },
    [navigate, dispatch, curPlatformValue, curCountryValue]
  )

  useEffect(() => { 
    if (curPlarformValue !== 'Blizzard' && curPlarformValue !== 'XBox' && curPlarformValue !== 'Steam') {
      setIsPlatformValid(false);
    } else {
      setIsPlatformValid(true);
    }
  }, [curPlarformValue]);

const sortedOptions = options.sort((a: string, b: string) => {
  const aStartsWithR = a.startsWith('R-');
  const bStartsWithR = b.startsWith('R-');

  if (aStartsWithR && !bStartsWithR) {
    return 1;
  } else if (!aStartsWithR && bStartsWithR) {
    return -1;
  } else {
    return a.localeCompare(b);
  }
});

return (
  <>
  
  {platformTitle === curPlarformValue && 
  <Form onFinish={handleSuccess} className={styles.inputs}>
              <div className={styles.menuGroup}>
                <ul className={styles.menu}>
                  {/* <li><a href="#" data-hover="Single">Single</a></li> */}
                  <li className={styles.menuCurrent}><a href="#" data-hover={platformTitle}>{platformTitle}</a></li>
                  {/* <li><a href="#" data-hover="From file">From file</a></li> */}
              </ul>
              {curPlatformValue.startsWith('R-') &&  <span>non-instant code verification</span>}
              {curPlarformValue === 'Steam' && <span>We accept all currencies</span>}
              </div>
              
              <span style={{opacity: '0.5'}}>Currencies</span>
              <div className={styles.radioGroup}>
                {isLoading ? (
                  <Spin />
                ) : (
                  <Radio.Group
                    onChange={handleChangeFilter}
                    value={curCountryValue && curRate ? `${curCountryValue}-${curRate}` : null}
                    optionType="button"
                    buttonStyle="solid"
                  >
                {sortedCurrencies && sortedCurrencies?.map((item: any, index) => (
                item?.groupDisabled ? <Tooltip title="Temporarily unavailable">
                  <Radio.Button
                  name="group"
                  value={`${item?.group}-${item?.rate}`}
                  key={index}
                  className={styles.radio}
                  disabled
                  >
                    {curPlarformValue === 'Amazon' ? item?.group === 'DE' ? 'DE/AT' : item?.group : item?.group} 
                    <br />
                    <small>Rate: {item?.rate ? (item?.rate * 100).toFixed(0) : 'n/a'}%</small>
                  </Radio.Button>
                  </Tooltip> :
                  <Radio.Button
                  name="group"
                  value={`${item?.group}-${item?.rate}`}
                  key={index}
                  className={styles.radio}
                  >
                    {curPlarformValue === 'Amazon' ? item?.group === 'DE' ? 'DE/AT' : item?.group : item?.group} 
                    <br />
                    <small>Rate: {item?.rate ? (item?.rate * 100).toFixed(0) : 'n/a'}%</small>
                  </Radio.Button>
                ))
                }
            </Radio.Group>
          )
                }
                <br />
                
              </div>

              <div style={{marginBottom: '18px'}}>
                {curRate ? (
                  <div className={styles.calculatorWrapper}>
                    <span style={{opacity: '0.5'}}>Rates Calculator</span>
                <div className={styles.calculator}>
                
                <InputNumber addonBefore='Amount' onChange={setAmount} value={amount} />
                <InputNumber addonBefore={currency === 'USD' ? '$' : '₦'} disabled value={currency === 'USD' ? `${currentUsdRate ? (amount * curRate * currentUsdRate).toFixed(2) : 'n/a'}` : `${currentUsdRate ? (amount * curRate * currentUsdRate).toFixed(2) : 'n/a'}`}/>
                </div>
                </div>
                ) : ( buttonDisabled ? <Alert type='warning' message="We aren't accepting codes in this group at the moment!"/> : <Alert message='Choose currency to calculate'/>)}
              </div>
              
              <Form.Item
                name="codes"
                rules={[
                  {
                    required: true,
                    message:  <span style={{ color: 'red' }}>Please enter codes!</span>,
                  },
                ]}
              >
                  <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 10 }} placeholder="Enter up to 20 codes here, one per line" />
              </Form.Item>
              <div className={styles.instructions}>
              <span>{instructions}</span>
              </div>
              <Form.Item name="notes">
                <TextArea rows={1} placeholder="Add note" autoSize={false} maxLength={40} />
              </Form.Item>
              {showRate &&
              <Form.Item name="customRate">
                <Input placeholder="Add rate" />
              </Form.Item>
              }
              <Form.Item>
                {curPlatformValue.startsWith('R-') ? <span className={styles.buttonText}>{codeFormatTypes["Non-Instant"]}</span> 
                : <span className={styles.buttonText}>{codeFormatTypes["Instant"]}</span>}
                  <div className={styles.submitButtonWrapper}>
                <Button aria-label="Submit codes button" className={styles.button} type="primary" htmlType="submit" disabled={isProcessing || buttonDisabled}>
                {isProcessing ? `Submitting...` : 'Submit'}
                </Button>
                </div> 
                
              </Form.Item>
            </Form>
            }
            </>
      )

}

export default SubmitForm
