import React, { useState } from "react";
import { Alert, Input } from "antd";

import styles from './styles.module.scss'

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, onChange, style, isValueInput, isValidAddress, defaultValue } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0) || (defaultValue)

  const labelClass = isOccupied ? `${styles.label} ${styles.asLabel}` : `${styles.asPlaceholder}`

  const inputClassName = required && value?.length === 0 ? `${styles.input} ${styles.requiredInput}` : `${styles.input}`
  const minorInputClassName = isValueInput ? `${styles.minorInput}` : null

  return (
    <>
    <div
      className={styles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input style={style} className={[inputClassName, minorInputClassName]} onChange={onChange} type={type} value={value} defaultValue={defaultValue} />
      <label className={labelClass}>
        {isOccupied ? label : placeholder}
      </label>
    </div>
    {required && !isValidAddress && 
    <span style={{color: 'red'}}>Please enter valid Payout Address!</span>
    }
    </>
  );
};

export default FloatInput;


