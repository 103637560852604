import { createAction, createReducer } from '@reduxjs/toolkit'
import frc from 'front-end-common'
import MyCodesService from '../services/MyCodesService'

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools

export const myCodesFetch = createAction('list', (params) => ({
  payload: MyCodesService.fetchMyCodes(params),
}))

export const codePackFetch = createAction('codePack', (params) => ({
  payload: MyCodesService.fetchCodePack(params),
}))

export const getGroups = createAction('groups',() => ({
    payload: MyCodesService.getMultGroups(),
  })
)

const initState = {
  codesList: getDefaultState(),
  codePack: getDefaultState(),
  groups: getDefaultState(),
}

export const myCodesReducer = createReducer(initState, {
  ...getDefaultHandler(myCodesFetch, 'codesList'),
  ...getDefaultHandler(codePackFetch, 'codePack'),
  ...getDefaultHandler(getGroups, 'groups'),
})

export default myCodesReducer
