import { combineReducers } from 'redux'
import myCodes from './myCodes'
import noAuthReducer from './noAuth'
import submitCodes from './submitCodes'
import user from './user'
import auth from './auth'

export default combineReducers({
  auth,
  myCodes,
  noAuthReducer,
  submitCodes,
  user
})
