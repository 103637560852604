import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Layout, Menu } from 'antd'
import UserProfile from '../UserProfile'
import ButtonSellCodes from '../ButtonSellCodes'
import MobMenu from './MobMenu'
// import { useSession } from 'next-auth/react'
import CustomImage from '../CustomImage'
import useMediaQuery from '../../utils/useMediaQuery'
import SignIn from '../SignIn'
import { BarcodeOutlined, DollarCircleOutlined, QuestionCircleOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons'
import SurveyBlock from '../SurveyBlock'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { myBalanceFetch } from '../../reducers/user'
import { useDispatch } from 'react-redux'
import { getDomainUrl, isClapswap } from 'front-end-common/src/utils/envDependent'
import { extractDomainName, extractDomainURL, isClapswapFlow } from '../../utils/extractDomain'

const AntHeader = Layout.Header

function Header() {

  const location = useLocation();
  const dispatch = useDispatch();

  // console.log('loaction', location.pathname)
  // const query = qs.parse(location.search.replace(/^\?/, ''));
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // navigate('/', { replace: true })

  // const { status } = useSession()

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  const media890 = useMediaQuery('(max-width: 890px)')
  const media490 = useMediaQuery('(max-width: 490px)')

  const userName = localStorage.getItem('username')

  const navItems = 
  media890 ? [
    {
      label: (
        
          <a href="/my-codes"><BarcodeOutlined style={{marginRight: '5px'}}/> Codes</a>
        
      ),
      key: 'my-codes',
    },
    {
      label: (
        
          <a href="/wallet"><WalletOutlined style={{marginRight: '5px'}}/>  Wallet</a>
        
      ),
      key: 'wallet',
    },
    {
      label: (
        
          <a href="/payouts"><DollarCircleOutlined style={{marginRight: '5px'}}/> Payouts</a>
        
      ),
      key: 'payouts',
    },
    {
      label: (
        
          <a href="/faq"><QuestionCircleOutlined style={{marginRight: '5px'}}/> FAQ</a>
        
      ),
      key: 'faq',
    },
    {
      label: (
          <a href="/usr-settings" className={styles.link}>
          <UserOutlined style={{marginRight: '5px'}}/> User Settings
          </a>
      ),
      key: 'usr_settings',
    },
    {
      label: (
        <small style={{opacity: '0.5'}}>{userName}</small>
      ),
      key: 'username'
    },
    {
      label: <SignIn />,
      key: 'Sign',
    },
  ] : [
    {
      label: (
        
          <a href="/">Sell codes</a>
        
      ),
      key: '/',
    },
    {
      label: (
        
          <a href="/my-codes">Codes</a>
        
      ),
      key: '/my-codes',
    },
    {
      label: (
        
          <a href="/wallet">Wallet</a>
        
      ),
      key: '/wallet',
    },
    {
      label: (
        
          <a href="/payouts">Payouts</a>
        
      ),
      key: '/payouts',
    },
    {
      label: (
        
          <a href="/faq">FAQ</a>
        
      ),
      key: '/faq',
    },
  ]
  
  useEffect(() => {

    if (isAuthenticated ) {
      dispatch(myBalanceFetch())
    }
    
  }, [dispatch, isAuthenticated])

  const { payload, isLoading } = useSelector((state: UserState) => state?.user?.balance, shallowEqual);

 
  const [isFetching, setIsFetching] = useState(false);

  return (
    <div className={styles.headerWraper}>

    <header className={styles.header}>

    <div className={styles.leftBlock}>
    
            <a href="/">
              {isClapswapFlow() ? <CustomImage
                src='/g2m_logo.png'
                srcSet='/g2m_logo.webp'
                alt='gift2money'
                width={191}
                height={32}
              /> : 
              <div className={styles.swapLogoWrapper}>
              <CustomImage 
              src='/favicon-96x96.png' 
              srcSet='/favicon-96x96.png' 
              width={35}
              height={35} 
              alt={extractDomainName(getDomainUrl())} />
              <b style={{fontSize: '25px'}}>{extractDomainName(getDomainUrl())}</b>
              </div>
              }
            </a>
    </div>

    <div className={styles.centerBlock}>
    
    {!media890 && 
          <Menu
            selectedKeys={[location.pathname]}
            mode="horizontal"
            className={styles.navigation}
            items={isAuthenticated ? navItems : []}
          />
        }
    </div>

    <div className={styles.rightBlock} style={!isAuthenticated ? {paddingTop: '12px'} : {}}>

      {isAuthenticated ? (
        media890 ?
        <MobMenu isLoading={isFetching} setIsLoading={setIsFetching} parsedBalance={payload} items={navItems} userName={userName} /> :
        <div className={styles.profile}>
        <UserProfile isAuthenticated={isAuthenticated} isLoading={isFetching} setIsLoading={setIsFetching} parsedBalance={payload} />
        <small className={styles.profile_userName}>{userName}</small>
        </div>
      ) : (
        // media490 ?
        // <MobMenu items={navLanding} /> :
        
        <UserProfile />
        
        
      )}
    </div>
      
    </header>
    </div>
  )
}

export default Header
