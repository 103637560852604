import React from 'react'
import {
  useRoutes
} from "react-router-dom"
import {
  UserOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  AccountBookOutlined,
  BarChartOutlined,
  BookOutlined,
  GlobalOutlined,
  LineChartOutlined,
  ReadOutlined,
  SettingOutlined,
  ShopOutlined,
  DesktopOutlined,
  FormOutlined,
  CompassOutlined,
  UserAddOutlined,

} from "@ant-design/icons"

import RedirectGuestToSignInPage from '../components/RedirectGuestToSignInPage'
import RedirectUserToMainPage from '../components/RedirectUserToMainPage'
import MyCodes from '../pages/my-codes'
import FAQ from '../pages/faq'
import Payouts from '../pages/payouts'
import Wallet from '../pages/wallet'
import UserSettings from '../pages/usr-settings'
import PrivacyPolicy from '../pages/privacy-policy'
import SignUp from '../pages/signup'
import LandingPage from '../pages/landing'
import SignInPage from '../pages/login'
import SubmitCodes from '../pages/submit-codes'
import { shallowEqual, useSelector } from 'react-redux'
import RootPage from '../pages/root'
import RedirectPage from '../pages/redirect-page'
import Custom404 from '../pages/not-found'
import Code from '../pages/my-codes/[id]'
import VerifyPage from '../pages/verify'




function Routes() {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  const element = useRoutes([
    {
      path: '*',
      element: <Custom404 />,
    },
    {
      path: '/verify',
      exact: true,
      element: isAuthenticated ? <SubmitCodes /> : <VerifyPage />,
    },
    {
      path: '/sign-in',
      exact: true,
      element: isAuthenticated ? <SubmitCodes /> : <SignInPage />,
    },
    {
      path: '/',
      exact: true,
      element: <RootPage />,
    },
    {
      path: '/redirect',
      // exact: true,
      element: <RedirectPage />,
    },
    {
      path: '/signup',
      exact: true,
      element: isAuthenticated ? <SubmitCodes /> : <SignUp />,
    },
    {
      path: '/privacy-policy',
      exact: true,
      element: <PrivacyPolicy />,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage />,
      children: [{
        path: '/',
        exact: true,
        element: <SubmitCodes />,
      },
      {
        path: '/my-codes',
        exact: true,
        element: <MyCodes />,
      },
      {
        path: '/my-codes/:id',
        exact: true,
        element: <Code />,
      },
      {
        path: '/faq',
        exact: true,
        element: <FAQ />,
      },
      {
        path: '/payouts',
        exact: true,
        element: <Payouts />,
      },
      {
        path: '/wallet',
        exact: true,
        element: <Wallet />,
      },
      {
        path: '/usr-settings',
        exact: true,
        element: <UserSettings />,
      },
      ],
    },
  ]);
  return element;
}

// export const links = [{
//   href: '/',
//   icon: <UserOutlined />,
//   title: 'Submit Codes',
// },
// {
//   href: '/wallet',
//   icon: <AccountBookOutlined />,
//   title: 'Purchase Jobs',
// },
// {
//   href: '/my-codes',
//   icon: <ReadOutlined />,
//   title: 'Redeem Jobs',
// },
// {
//   href: '/pa',
//   icon: <UnorderedListOutlined />,
//   title: 'Other Jobs',
// },
// {
//   href: '/archive-accounts',
//   icon: <BookOutlined />,
//   title: 'Archive accounts',
// },
// {
//   href: '/virtual-machines',
//   icon: <DesktopOutlined />,
//   title: 'Virtual Machines',
// },
// {
//   href: '/proxies',
//   icon: <GlobalOutlined />,
//   title: 'Proxies',
// },
// {
//   href: '/purchase-statistics',
//   icon: <BarChartOutlined />,
//   title: 'Purchase statistics',
// },
// {
//   href: '/redeem-order',
//   icon: <FormOutlined />,
//   title: 'Redeem order',
// },
// {
//   href: '/algo-statistics',
//   icon: <BarChartOutlined />,
//   title: 'Algo statistics',
// },
// {
//   href: '/lost-and-found',
//   icon: <CompassOutlined />,
//   title: 'Lost and found',
// },
// {
//   href: '/accounts_to_create',
//   icon: <UserAddOutlined />,
//   title: 'Accounts to create',
// },
// {
//   href: '/config',
//   icon: <ProfileOutlined />,
//   title: 'Config',
// },
// ];

export default Routes;
