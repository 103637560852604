import styles from './styles.module.scss'
import { message } from 'antd'

const messageSuccess = (content: string) => {
  message.success({
    content: content,
    className: styles.success,
  })
}

const messageError = (content: string) => {
  message.error({
    content: content,
    className: styles.error,
  })
}

export { messageSuccess, messageError }
