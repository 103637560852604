import React from "react";

export default function Custom404() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '48px',
        minHeight: '150px',
      }}
    >
      <h1>404 - </h1>
      <h2 style={{ marginLeft: '16px', marginRight: 0 }}>Page Not Found</h2>
    </div>
  )
}
