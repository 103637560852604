import ApiService from './ApiService';
import { queryParams } from '../types/api';

const CURRENT_BALANCE_LS_KEY = 'balance';

const UserService = {

  signUp(email: string, password: string, agreedToReceivePromo: boolean, utmData: JSON, referrer: number) {
    return ApiService.post('/register', {
      email,
      password,
      agreedToReceivePromo,
      utmData,
      referrer
    })
  },

  verify(userId: number, verificationCode: string) {
    return ApiService.post('/verify_email', { userId, verificationCode })
  },

  login(username: string, password: string) {
    return ApiService.post('/login', { username, password }) 
  },

  sendResetRequest(username: string) {
    return ApiService.post(`/password_recover/send`, {userEmail: username}) 
  },

  getResetToken(userEmail: string, restoreToken: string) {
    return ApiService.get(`/password_recover/get_token`, {userEmail, restoreToken}) 
  },

  restorePassword(userEmail: string, newPassword: string, restoreToken: string) {
    return ApiService.post(`/password_recover/reset`, {userEmail, newPassword, restoreToken}) 
  },

  fetchMyBalance() {
    return ApiService.get('/user/balance')
  },

  fetchMyWallet() {
    return ApiService.get('/wallet')
  },

  fetchMyPayouts() {
    return ApiService.get('/payouts')
  },

  fetchMyTransactions({ filters, ...params }: queryParams) {
    const updatedParams: any = { ...params }

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',')
      }
    }
    return ApiService.get('/transactions', updatedParams)
  },

  fetchMyWithdrawal() {
    return ApiService.get('/payout/page_data')
  },

  postWithdrawal(amount: number, payoutType: string | null, btcAddress: string, password?: string, data?: any) {
    return ApiService.post('/payout', {
      amount: amount,
      payoutType: payoutType,
      btcAddress: btcAddress,
      password,
      ...data
    })
  },

  setUserPassword(currPassword: string, newPassword: string, isPayout: boolean) {
    return ApiService.post('/user/password', {
      currPassword: currPassword,
      newPassword: newPassword,
      isPayout: isPayout
    })
  },

  changeUserCurrency(value: string) {
    return ApiService.post('/user/info', value)
  },

  getMultGroups() {
    return ApiService.get('/codes/groups',)
  },

  getCurrentBalanceFromLS() {
    return JSON.parse(localStorage.getItem(CURRENT_BALANCE_LS_KEY) || '{}');
  }
}

export default UserService
