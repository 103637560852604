import React, {FC, ReactNode} from 'react'
import {Layout} from 'antd'
import { Outlet } from 'react-router-dom'

interface Props {
  children?: ReactNode
}

const MainLayout: FC<Props> = ({ children, ...props }): React.ReactElement => {

  return (
    <>
      <Layout className="mainLayout">
        <Outlet />
      </Layout>
    </>
  )
}

export default MainLayout
