import Cookies from 'js-cookie';

class TokenService {
  constructor() {
    // this.cbs = [];
    this.tokenKey = 'auth_token';
  }

  hasToken() {
    return this.getToken();
  }

  getToken() {
    return Cookies.get(this.tokenKey) || null;
  }

  setToken(token) {
    Cookies.set(this.tokenKey, token, { expires: 7 });
  }

  removeToken() {
    Cookies.remove(this.tokenKey);
    localStorage.removeItem('username')
    localStorage.removeItem('referrer')
  }
}

export default new TokenService();
