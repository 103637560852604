import { Result } from 'antd'
import React from 'react'

const NotAvailable = () => {
  return (
    <Result
                  status="500"
                  title="500"
                  subTitle="Sorry, this page is currently unavailable. Please, come back later or contact us!"
                />
  )
}

export default NotAvailable
