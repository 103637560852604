import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Space, Divider } from 'antd'
import { UserState } from '../../types/rootState'
import { myWalletFetch, myTransactionsFetch, myWithdrawalFetch } from '../../reducers/user'
import MyWalletTable from './MyWalletTable'
import MyTransactionsTable from './MyTransactionsTable'
import TableFeatures from '../../components/TableFeatures'
import Withdrawal from '../../components/Withdrawal'

const { Title } = Typography

const Wallet = () => {
  const dispatch = useDispatch()

  const { params, pagination, onChange } = TableFeatures()

  useEffect(() => {
    dispatch(myWalletFetch())
  }, [dispatch])

  useEffect(() => {
    dispatch(myTransactionsFetch(params))
    dispatch(myWithdrawalFetch())
  }, [dispatch, params])

  const { wallet, transactions } = useSelector((state: UserState) => state?.user) || {}

  return (
    <div >
      <Space
      direction="vertical"
      size="large"
      style={{ display: 'flex', maxWidth: '1280px', margin: '24px auto', width: '85vw' }}
      >
        <Title level={1}>Wallet</Title>
        <Withdrawal withdrawalText={wallet?.payload?.withdrawalText || ""}  />
        <MyWalletTable payload={wallet?.payload} isLoading={wallet?.isLoading} />
        <Divider />
        <Title level={2}>Transactions</Title>
        <MyTransactionsTable
          payload={transactions?.payload}
          isLoading={transactions?.isLoading}
          pagination={pagination(transactions?.payload?.total)}
          onChange={onChange}
        />
      </Space>
    </div>
  )
}

export default Wallet
