import React from 'react'
import styles from './styles.module.scss'
import { SuccessIcon, ErrorIcon, PendingIcon } from '../SvgSprite'

interface TagProps {
  color?: string
  text: string
}

const Tag: React.FC<TagProps> = ({ color, text }) => {
  return (
    <span
      className={styles.tag}
      data-color={color} // red | green | yellow | null
    >
      {(color === 'red' && <ErrorIcon />) ||
        (color === 'green' && <SuccessIcon />) ||
        (color === 'yellow' && <PendingIcon />)}
      {text}
    </span>
  )
}

export default Tag
