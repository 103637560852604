import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomImage from '../CustomImage';
import styles from './styles.module.scss'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { platformGroupsFetch } from '../../reducers/submitCodes';
import { Divider, Select, Skeleton } from 'antd';
// import Carousel from 'react-bootstrap/Carousel';
// import Carousel from "react-elastic-carousel";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import useMediaQuery from '../../utils/useMediaQuery';




const findHighestRateGroup = (groups, selectedCurrency) => {
  if (!groups) return null;

  let highestRate = 0;
  let highestRateGroup = null;

  for (const group of groups) {
    if (group.group === selectedCurrency) {
      return group; // If a group matches exactly, return it.
    }

    if (group.rate > highestRate) {
      highestRate = group.rate;
      highestRateGroup = group;
    }
  }

  return highestRateGroup;
};

function chunkArray(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

const PlatformCard: React.FC = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(platformGroupsFetch())
  }, [])


  // Получение и запись наиболее высоких рейтов платформ
  const submitData = useSelector((state) => state?.submitCodes?.platformsList, shallowEqual)

  const { currencies, supportedCards, ngnRates, usdRates, usdtUsdRate, btcUsdRate, isloading } = {
    currencies: submitData?.payload?.countryCurrencies,
    supportedCards: submitData?.payload?.supportedCards,
    ngnRates: submitData?.payload?.ngnRates,
    usdRates: submitData?.payload?.usdRates,
    usdtUsdRate: submitData?.payload?.usdtUsdRate,
    btcUsdRate: submitData?.payload?.btcUsdRate,
    isloading: submitData?.isloading
  }

  // console.log('currencies', supportedCards)

  const uniqueCurrencies = currencies && [...new Set(Object.values(currencies))];

    // const currencyArray = currencies && uniqueCurrencies.map(currency => ({
    //   label: currency,
    //   value: currency
    // }));

    // Временный массив только для трех валют
    const currencyArray = [{label: "USD", value: "USD"}]

  const [currentCurrency, setCurrentCurrency] = useState('USD')
  const handleChangeCurrency = useCallback((e: any) => {
    // console.log('e', e.target.value)
    setCurrentCurrency(e.target.value)
  }, [])

  const findMatchingRates = (currency) => {
    // Step 1: Find matching groups based on the selected currency in supportedCards
    const matchingPlatforms = {};
  
    for (let platform in supportedCards) {

      if (platform !== 'R-Netflix') {
      const matchingGroups = supportedCards[platform].filter((data) => data.group === currency);
      if (matchingGroups.length > 0) {
        const rate = matchingGroups[0].rate;
  
        const keyWithoutPrefix = platform.replace(/^R-/, '');
        if (!matchingPlatforms[keyWithoutPrefix] || matchingPlatforms[keyWithoutPrefix] < rate) {
          matchingPlatforms[keyWithoutPrefix] = rate;
        }
      }
    }
  }
  
    // Step 2: Find the country that matches the selected currency in countryCurrencies
    for (let country in currencies) {
      if (currencies[country] === currency) {
        const countryMatchingRates = findMatchingRates(country);
        for (let platform in countryMatchingRates) {
          const keyWithoutPrefix = platform.replace(/^R-/, '');
          if (!matchingPlatforms[keyWithoutPrefix] || matchingPlatforms[keyWithoutPrefix] < countryMatchingRates[platform]) {
            matchingPlatforms[keyWithoutPrefix] = countryMatchingRates[platform];
          }
        }
      }
    }
  
    return matchingPlatforms;
  };

  function currencyToNGN (currency: string, rate: number) {
    const currencyValue = ngnRates[currency]
    return (currencyValue * rate * 100).toFixed(1)
  }

  function currencyToUSDT (currency: string, rate: number) {
    const currencyValue = usdRates[currency];
    return (currencyValue * rate * 100 * usdtUsdRate).toFixed(0)
  }

  function currencyToBTC (currency: string, rate: number) {
    const currencyValue = usdRates[currency];
    return (currencyValue * rate * 100 / btcUsdRate).toFixed(4)
  }

  const getDisplayData = () => {
    const matchingRates = findMatchingRates(currentCurrency);
    const sortedRates = Object.fromEntries(
      Object.entries(matchingRates).sort(([, a], [, b]) => b - a)
    );

    return Object.keys(sortedRates).map((platform) => {
      // return `${platform.startsWith('R-') ? platform.replace('R-', '') : platform}: ${matchingRates[platform]}`;
      const platformName = platform.startsWith('R-') ? platform.replace('R-', '') : platform
      const currentRate = matchingRates[platform]
      const preparePlatformName = platformName.toLowerCase()
      return (
        <>
          <a href="/signup">
          <CustomImage src={`logo_${preparePlatformName}.svg`} alt={`${platformName}_logo`} width={60} height={60} />
          </a>
        <div className={styles.ratesWrapper}>
        <span className={styles.boldSpan}><span className={styles.label}>Rate</span> <span className={styles.value}>{`${(matchingRates[platform] * 100).toFixed(0)}%`}</span></span>
        
        <span className={styles.boldSpan}><span className={styles.label}>USDT</span> <span className={styles.value}>{currencyToUSDT(currentCurrency, currentRate)}</span></span>
        
        <span className={styles.boldSpan}><span className={styles.label}>NGN</span> <span className={styles.value}>{currencyToNGN(currentCurrency, currentRate)}</span></span>

        <span className={styles.boldSpan}><span className={styles.label}>BTC</span> <span className={styles.value}>{currencyToBTC(currentCurrency, currentRate)}</span></span>
        </div>
     
        </> 
      
      )
    });
  };


  const ref = useRef(null);

  const media768 = useMediaQuery('(max-width: 820px)')

  return (
    
    <>
    <span className={styles.giftCards__currenciesBlock}>Sell card for 100 
    <select onChange={handleChangeCurrency} value={currentCurrency} className={styles.giftCards__currenciesBlock_select}>
        {currencyArray?.map((currency, index) => (
          <option key={index} value={currency.value}>{currency.label}</option>
        ))
        }
    </select>
      and receive:
      </span>
      {!currencies && <div className={styles.giftCards__availableWithdraw_default}><Skeleton.Input style={{height: '256px', width: '50vw'}} active={true} /></div>}
      {getDisplayData().length > 3 ? (
      <div className={styles.giftCards__availableWithdraw}>
        
          <Carousel
          swipeable
          arrows={false}
          ref={ref}
          centerMode
          // draggable
          // customTransition="all 1s ease-out"
          // customTransition={media768 ? 'linear 1s' : ''}
          customTransition='linear 0.6s'
          // transitionDuration={5000}
          className={styles.carousel_container_with_scrollbar}
          // containerClass={styles.carousel_container_with_scrollbar}
          // draggable
          infinite
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 3,
              partialVisibilityGutter: 100
            },
            mobile: {
              breakpoint: {
                max: 520,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 521
              },
              items: 2,
              partialVisibilityGutter: 100
            }
          }}
          >
          {getDisplayData().map((display, index) => (
              <div key={index} className={styles.giftCards__availableWithdraw_cards}>
              {display}
              </div>
          ))}
          </Carousel>
           
          {!media768 && <div className={styles.custom_slider__input}>
                  <button
                  onClick={() => { ref.current.previous()}}
                  aria-label="Go to previous slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" type="button"></button>
                  <button
                  onClick={() => { ref.current.next()}}
                  aria-label="Go to next slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right " type="button"></button>
          </div> }
          </div>
          ) : (
          <div className={styles.giftCards__availableWithdraw_default}>
            {getDisplayData().map((display, index) => (
            <div key={index} className={styles.giftCards__availableWithdraw_default_cards}>
            {display}
            </div> 
        ))}
        </div>
        )} 
          </>
  );
};

export default PlatformCard;
