import React from 'react';
import { Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import MainLayout from '../MainLayout';

function RedirectGuestToSignInPage() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  // console.log('isAuthenticated', isAuthenticated)

  return isAuthenticated ? <MainLayout /> : <Navigate to={`/sign-in`} />;
}

export default RedirectGuestToSignInPage;
