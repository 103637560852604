import currencySymbolsCollection from './currencySymbolsCollection.json'

export default function ratesToDisplay(ratesObject) {

  let ratestoValuesArray = []

  for (let key in ratesObject) {
    ratestoValuesArray.push({ valueOtherCurrency: `${currencySymbolsCollection[key].symbol}1`, valueNaira: `₦${ratesObject[key].toFixed(1)}` })
  }

  return ratestoValuesArray
}
