import React, { useEffect, useMemo, useState } from 'react'
import { Button, Divider, Modal, Skeleton, Spin } from 'antd'
import { shallowEqual, useSelector } from 'react-redux'
import { UserState } from '../../types/rootState'
import useMediaQuery from '../../utils/useMediaQuery'
import CurrentTime from '../CurrentTime'
//@ts-ignore
import styles from './styles.module.scss'
import SubmitWithdrawal from './SubmitWithdrawal'

interface WithdrawalWindow {
  withdrawalText: string
}

const Withdrawal: React.FC<WithdrawalWindow> = ({ withdrawalText }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [debounceBalance, setDebounceBalance] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const balance = localStorage.getItem('balance')

  // const payload = balance && JSON.parse(balance)
  
  const { payload, isLoading } = useSelector((state: UserState) => state?.user?.balance, shallowEqual) || {}

  // console.log('walletloading', payload)

  useEffect(() => {
    if (isLoading) {
      setDebounceBalance(true)
      setTimeout(() => {
        setDebounceBalance(false)
      }, 2000)
    }
  }, [isLoading])
  // const { isLoading } = useSelector((state: UserState) => state?.user?.balance, shallowEqual) || {}

  const { actual, pending, currency } = useMemo(
    () => ({
      actual: (payload && payload.actual) || '',
      pending: (payload && payload.pending) || '',
      currency: (payload && payload.currency) || 'USD',
    }),
    [payload]
  )

  const balanceAmount = useMemo(
    () =>
      new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(+actual),
    [actual, currency]
  )


  const pendingAmount = useMemo(
    () =>
      new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(+pending),
    [pending, currency]
  )

  const media768 = useMediaQuery('(max-width: 768px)')

  const balanceUpdated = parseInt(actual)

  // console.log('BALANCE', payload)

  return (
    <>
    <div className={styles.card}>
    <div className={styles.balanceBlock}>
      <div className={styles.withdrawalBlock}>
        <div className={styles.pendingBalance}>
          <span className={styles.pendingText}>Pending</span>
          <span className={styles.pendingAmount}>
              {isLoading ? <Skeleton.Input style={{height: '24px', marginTop: '8px'}} active size='small' /> : pendingAmount}
              {/* {isLoading ? <Skeleton.Input style={{height: '24px', marginTop: '8px'}} active size='small' /> : '₦ 579442,78'} */}
          </span>
          </div>
        </div>
        <Divider style={{height: 'auto'}} type="vertical" />
        <div className={styles.withdrawalBlock}>
          <div className={styles.availableBalance}>
            <span className={styles.availableText}>Available</span>
            <span className={styles.availableAmount}>
                {isLoading ? <Skeleton.Input style={{height: '24px', marginTop: '8px'}} active size='small' /> : balanceAmount}
                {/* {isLoading ? <Spin size="small" style={{ margin: '-2px 5px 0' }} /> : '₦ 579442,78'} */}
            </span>
          </div>
            <Button onClick={showModal} className={styles.button} type="primary">
                Withdrawal
            </Button>
          </div>
        </div>
      <Modal
      centered
      title=""
      footer={null}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className={styles.modal}
      >
        <SubmitWithdrawal currency={currency} closeModal={closeModal} balanceAmount={balanceAmount} actual={actual} close={closeModal}/>
      </Modal>
    
    <Divider style={{height: 'auto'}} type={media768 ? "horizontal" : "vertical"} />
    <div className={styles.text}><div dangerouslySetInnerHTML={{ __html: withdrawalText }} /> (<CurrentTime /> now)</div>

    </div>
    
    </>
  )
}

export default Withdrawal
