import React from "react"

export const User = ({ fillColor = '#32343C' }) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21c1.81 0 3.4952-.5343 4.9065-1.4538-1.0861-1.5399-2.8788-2.5457-4.9064-2.5457-2.02768 0-3.82042 1.0058-4.90653 2.5457C8.50484 20.4657 10.19 21 12 21Zm9-9c0 2.4391-.9703 4.6516-2.5459 6.2725-1.4559-1.984-3.8045-3.272-6.454-3.272-2.64956 0-4.99825 1.2881-6.45412 3.2721C3.9703 16.6517 3 14.4391 3 12c0-4.97056 4.02944-9 9-9 4.9706 0 9 4.02944 9 9Zm-9 11c6.0751 0 11-4.9249 11-11C23 5.92487 18.0751.999999 12 1 5.92487 1 .999999 5.92487 1 12c0 6.0751 4.92487 11 11 11Zm2-13c0 1.1046-.8954 2-2 2s-2-.8954-2-2c0-1.10457.8954-2 2-2s2 .89543 2 2Zm2 0c0 2.2091-1.7909 4-4 4-2.20914 0-4-1.7909-4-4 0-2.20914 1.79086-4 4-4 2.2091 0 4 1.79086 4 4Z"
        fill={fillColor}
      />
    </svg>
  )
}

export const UserIconProfile = ({ fillColor = '#32343C' }) => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33 60C38.43 60 43.4857 58.3971 47.7196 55.6386C44.4612 51.019 39.0831 48.0017 33.0002 48.0017C26.9173 48.0017 21.5391 51.019 18.2807 55.6387C22.5145 58.3971 27.5701 60 33 60ZM60 33C60 40.3173 57.0892 46.9548 52.3623 51.8176C47.9947 45.8656 40.9487 42.0016 33.0002 42.0017C25.0516 42.0017 18.0055 45.8657 13.6379 51.8178C8.91091 46.955 6 40.3174 6 33C6 18.0883 18.0883 6 33 6C47.9117 6 60 18.0883 60 33ZM33 66C51.2254 66 66 51.2254 66 33C66 14.7746 51.2254 -2.23913e-06 33 -1.44248e-06C14.7746 -6.45818e-07 -2.23913e-06 14.7746 -1.44248e-06 33C-6.45818e-07 51.2254 14.7746 66 33 66ZM39 27C39 30.3137 36.3137 33 33 33C29.6863 33 27 30.3137 27 27C27 23.6863 29.6863 21 33 21C36.3137 21 39 23.6863 39 27ZM45 27C45 33.6274 39.6274 39 33 39C26.3726 39 21 33.6274 21 27C21 20.3726 26.3726 15 33 15C39.6274 15 45 20.3726 45 27Z" fill="#32343C" />
    </svg>
  )
}

export const CheckIconFalse = ({ fillColor = '#32343C' }) => {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7071 0.368239C12.3166 -0.0222848 11.6834 -0.022285 11.2929 0.368239L4.92886 6.73227L2.63597 4.43938C2.24544 4.04885 1.61228 4.04885 1.22176 4.43938C0.831231 4.8299 0.831231 5.46307 1.22176 5.85359L4.05018 8.68202C4.05356 8.68539 4.05695 8.68874 4.06036 8.69206L4.22183 8.85352C4.61235 9.24404 5.24551 9.24404 5.63604 8.85352L12.7071 1.78245C13.0976 1.39193 13.0976 0.758764 12.7071 0.368239Z"
        fill={fillColor}
        fillOpacity='0.3'
      />

    </svg>
  )
}

export const CheckIcon = ({ fillColor = '#31C27C' }) => {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7071 0.368239C12.3166 -0.0222848 11.6834 -0.022285 11.2929 0.368239L4.92886 6.73227L2.63597 4.43938C2.24544 4.04885 1.61228 4.04885 1.22176 4.43938C0.831231 4.8299 0.831231 5.46307 1.22176 5.85359L4.05018 8.68202C4.05356 8.68539 4.05695 8.68874 4.06036 8.69206L4.22183 8.85352C4.61235 9.24404 5.24551 9.24404 5.63604 8.85352L12.7071 1.78245C13.0976 1.39193 13.0976 0.758764 12.7071 0.368239Z"
        fill={fillColor}
      />

    </svg>
  )
}

export const Telegram = () => {
  return (
    <svg width="16" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.27812 9.12061-.26467 3.72269c.37867 0 .54267-.1627.73933-.358l1.77534-1.6967 3.67868 2.694c.6747.376 1.15.178 1.332-.6207l2.4147-11.31462.0006-.00067c.214-.997332-.3606-1.387332-1.018-1.142665L.742785 5.83794c-.968666.376-.954.916-.164666 1.16067L4.20678 8.12728l8.42872-5.274c.3966-.26267.7573-.11734.4606.14533l-6.81798 6.122Z"
        fill="#32343C"
      />
    </svg>
  )
}

export const ICQ = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#32343C">
        <path d="m8.49756 5.46163.96193-1.93816-.00408.00027c.18546-.3799.27695-.81184.24624-1.26365C9.60804.881403 8.4125-.160197 7.03137-.0663961 5.65024.0274051 4.60652 1.22108 4.70018 2.59977c.03166.46667.18963.89471.43827 1.25314l1.15242 1.69527c.22093.41123.66867.67745 1.16546.64371.47179-.03202.86315-.32614 1.04123-.73026Zm1.90914 2.70505 2.3915.35476-.0019-.00475c.471.0663.965.01395 1.4378-.17596 1.443-.57967 2.1421-2.2215 1.5614-3.66713-.5807-1.44558-2.2213-2.14759-3.6644-1.56791-.4961.19932-.9043.52414-1.2027.92343l-1.465 1.87815c-.01703.02056-.03343.04162-.04923.06309l-.00648.00833.00059-.00022c-.26857.37143-.34638.86881-.16268 1.3261.20068.4996.65988.81782 1.1611.86211ZM8.38729 10.6439l.25603 2.1595.00321-.0024c.05385.4259.2164.8437.49372 1.2105.84693 1.1206 2.44355 1.3412 3.56615.4928 1.1226-.8485 1.346-2.4447.499-3.5653-.2924-.3869-.6742-.6666-1.095-.8319l-1.9042-.79241c-.4159-.22364-.94199-.19973-1.34399.10408-.3905.2952-.55884.77513-.47492 1.22513ZM5.2317 9.41772 3.10359 10.5285l.00381.0024c-.41804.2221-.78516.5533-1.05477.9828-.82293 1.3112-.42528 3.0424.88819 3.8668 1.31351.8245 3.04542.4298 3.8684-.8813.28107-.4479.41975-.9447.42822-1.4376l.07142-2.2838c.06939-.5174-.16114-1.05215-.63275-1.34817-.45567-.28596-1.01419-.27242-1.44441-.01191Zm.30464-2.85679L4.2491 5.30058l-.00082.00326c-.25308-.24443-.57156-.42791-.9371-.51985-1.11605-.28062-2.24867.3982-2.529789 1.51624-.281117 1.118.395699 2.25181 1.511709 2.53243.36558.09194.73298.08094 1.07161-.01476l-.00004.00018 1.68014-.48837c.37926-.07156.70168-.35653.80214-.75604.09606-.38198-.03624-.76772-.31061-1.01274Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ErrorIcon = () => {
  return (
    <img src="/icon_error.svg" alt="logo" />
  )
}

export const PendingIcon = () => {
  return (
    <img src="/icon_pending.svg" alt="logo" />
  )
}

export const SuccessIcon = () => {
  return (
    <img src="/icon_success.svg" alt="logo" />
  )
}

export const ArrowIcon = () => {
  return (
    <img style={{ paddingBottom: "5px", paddingLeft: "10px", paddingRight: "10px" }} src="/icon_arrow.svg" alt="logo" />
  )
}

// export const ErrorIcon = () => {
//   return (
//     <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <circle cx="8" cy="8" r="7" fill="#FE4A6A" />
//       <path d="M7 5c0-.55228.44772-1 1-1s1 .44772 1 1v3c0 .55228-.44772 1-1 1s-1-.44772-1-1V5Z" fill="#fff" />
//       <circle cx="8" cy="11" r="1" fill="#fff" />
//     </svg>
//   )
// }

// export const PendingIcon = () => {
//   return (
//     <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <circle cx="8" cy="8" r="7" fill="#FFD053" />
//       <rect x="6" y="4" width="2" height="6" rx="1" fill="#fff" />
//       <rect
//         x="10.4102"
//         y="10.9961"
//         width="2"
//         height="4.82821"
//         rx="1"
//         transform="rotate(135 10.4102 10.9961)"
//         fill="#fff"
//       />
//     </svg>
//   )
// }

// export const SuccessIcon = () => {
//   return (
//     <svg width="27" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <circle cx="13.5" cy="12.5" r="6.5" fill="#31C27C" />
//       <rect width="2" height="8" rx="1" transform="scale(-1 1) rotate(-45 3.62091024 27.22776098)" fill="#fff" />
//       <rect width="2" height="5" rx="1" transform="scale(-1 1) rotate(45 -18.77758004 -6.94982858)" fill="#fff" />
//     </svg>
//   )
// }

const svgSprite = { User, Telegram, ICQ, ErrorIcon, SuccessIcon, PendingIcon }

export default svgSprite
