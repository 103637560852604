/* eslint-disable */
import qs from 'qs'
import TokeService from './TokenService'

const API_PREFIX = process.env.REACT_APP_API_PREFIX
const CONTENT_TYPE_JSON = 'application/json'
const CONTENT_TYPE_STREAM = 'application/octet-stream'
const CONTENT_TYPE_CSV = 'text/csv'

export function prepareQueryString(params) {
  const updParams = Object.keys(params)
    .filter((key) => params[key] !== null)
    .reduce((acc, cur) => ({ ...acc, [cur]: params[cur] }), {})

  return qs.stringify(updParams, { encode: false, arrayFormat: 'brackets' })
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true })
}

class ApiService {
  constructor(ts) {
    this.ts = ts
  }

  getApiLink(link, params) {
    return API_PREFIX + link + (params ? `?${prepareQueryString(params)}` : '')
  }

  async call(url, method = 'GET', options = {}, params = null) {
    const headers = options.headers || {}
    headers['X-Requested-With'] = 'XMLHttpRequest'

    const token = await this.ts.getToken()
    if (token !== null) {
      headers.Authorization = `${token}`
    }

    for (const headerKey in options.headers || {}) {
      if (options.headers.hasOwnProperty(headerKey)) {
        headers[headerKey] = options.headers[headerKey]
      }
    }

    options.headers = headers
    options.method = method
    options.credentials = 'include'
    options.mode = 'cors'

    return await fetch(this.getApiLink(url, params), options)
      .then((resp) => {
        let result
        const contentType = resp.headers.get('Content-Type')
        let fileName = null
        let type = 'text'

        if (resp.status === 204) {
          return [{}, resp.status]
        }

        if (contentType && contentType.includes(CONTENT_TYPE_JSON)) {
          result = resp.json()
          type = 'json'
        } else if (contentType && contentType.includes(CONTENT_TYPE_STREAM)) {
          result = resp.blob()
          fileName = resp.headers.get('content-disposition')
          type = 'blob'
        } else if (contentType && contentType.includes(CONTENT_TYPE_CSV)) {
          fileName = resp.headers.get('content-disposition')
          type = 'csv'
          result = resp.text()
        } else {
          result = resp.text()
        }
        return Promise.all([result, resp.status, fileName, type])
      })
      .then(([data, status, fileName, type]) => {
        if (status === 403 || status === 401) {
          this.ts.removeToken()
        }

        if (status >= 500 || [400, 401, 402, 403, 404, 415].includes(status)) {
          return Promise.reject(data)
        }

        if (['blob', 'csv'].includes(type)) {
          return {
            data,
            type,
            fileName: fileName.match(/\\".+\\"/)[0].replace(/\\"/g, ''),
          }
        }

        return typeof data.data === 'undefined' ? data : data.data
      })
  }

  get(url, params, options = {}) {
    return this.call(url, 'GET', options, params)
  }

  post(url, data, options = {}) {
    if (data) {
      options.body = JSON.stringify(data)
      options.headers = {
        'Content-Type': CONTENT_TYPE_JSON,
      }
    }

    return this.call(url, 'POST', options)
  }

  put(url, data, options = {}) {
    if (data) {
      options.body = JSON.stringify(data)
      options.headers = {
        'Content-Type': CONTENT_TYPE_JSON,
      }
    }

    return this.call(url, 'PUT', options)
  }

  upload(url, file, name) {
    const formData = new FormData()
    formData.append(name, file)

    const options = {
      body: formData,
    }

    return this.call(url, 'POST', options)
  }

  delete(url, data) {
    let options = null

    if (data) {
      options = {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': CONTENT_TYPE_JSON,
        },
      }
    }

    return this.call(url, 'DELETE', options)
  }

  patch(url, data) {
    let options = null

    if (data) {
      options = {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': CONTENT_TYPE_JSON,
        },
      }
    }

    return this.call(url, 'PATCH', options)
  }
}

export default new ApiService(TokeService)
