import React from 'react'
import SignupComponent from '../../components/SignUp'


const SignUp = () => {

  return (
    <>
      <SignupComponent />
    </>
  )
}

export default SignUp
