import { Radio } from 'antd'
import React from 'react'
//@ts-ignore
import styles from './styles.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { PayoutCommon } from '../../types/rootState';

interface PayoutSwitcher {
  handleChangeForm: any,
  formType: string,
  isOutsideOfWithdrawalForm?: boolean,
  commonPayoutInfo?: Record<string, PayoutCommon>
}

const PayoutMethodSwtich: React.FC<PayoutSwitcher> = ({ handleChangeForm, formType, isOutsideOfWithdrawalForm }) => {

  const containerStyle = isOutsideOfWithdrawalForm
    ? `${styles.radioGroup} ${styles.radioGroup_margin}`
    : styles.radioGroup;

    const commonPayoutInfo = useSelector((state) => state?.user?.withdrawal?.payload?.payoutCommon, shallowEqual)

    // console.log(`payoutCommon: ${Object.keys(payout_page_data)}`)

    const payoutCommon = commonPayoutInfo && Object.keys(commonPayoutInfo).reverse()

  return (
    <div className={containerStyle}>
        <Radio.Group
          onChange={handleChangeForm}
          value={formType}
          optionType="button"
          buttonStyle="solid"
        >
          {payoutCommon?.map((item) => (
            <Radio.Button
            key={item}
            name="group"
            value={item}
            className={styles.radio}
            >
              {commonPayoutInfo[item].title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
  )
}

export default PayoutMethodSwtich
