import { Typography } from 'antd'
import React from 'react'

const { Title } = Typography

const FAQ = () => (
  <div style={{maxWidth: '1280px', margin: '24px auto', width: '85vw'}}>
    <Title level={1}>FAQ</Title>

    <section className="faq">
      <p>
        <Title level={5}>How to&nbsp;post codes?</Title>
        <p>
          Press&nbsp;
            <a href="/submit-codes" className="tableLink">
              sell codes
            </a>
          &nbsp; in&nbsp;the top left corner. Enter you code(s) and press &laquo;Submit codes&raquo;.
        </p>
      </p>

      <p>
        <Title level={5}>How much BTC will I&nbsp;get?</Title>
        <p>
          When you successfully submit a&nbsp;valid code your balance increases on&nbsp;code reward amount (based
          on&nbsp;your reward rate).
          <br />
          When you decide to&nbsp;withdraw your balance is&nbsp;converted to&nbsp;BTC using current market BTC exchange
          rate.
          <br />
          {/* You can estimate your reward for codes on&nbsp;page &laquo;Submit code&raquo; in&nbsp;part &laquo;Estimate your reward&raquo;. */}
        </p>
      </p>

      <p>
        <Title level={5}>Can I&nbsp;post 2&nbsp;codes or&nbsp;more?</Title>
        <p>Yes, you can send up&nbsp;100 codes per one pack. Just one rule: 1&nbsp;code per 1&nbsp;line.</p>
      </p>

      <p>
        <Title level={5}>How fast are the codes processed?</Title>
        <p>1-20 codes in&nbsp;about 2&nbsp;minutes.&nbsp;100 codes in&nbsp;about 8&nbsp;minutes.</p>
      </p>

      <p>
        <Title level={5}>Codes are checked. Where is&nbsp;my&nbsp;money?</Title>
        <p>
          Press on&nbsp;67.30&nbsp;USD found on&nbsp;the top right. Press &laquo;Withdraw&raquo;. Enter amount, your BTC
          wallet address and press &laquo;Pay out&raquo;.
        </p>
      </p>

      <p>
        <Title level={5}>Where can&nbsp;I find payout status?</Title>
        <p>
          Press
            <a href="/payouts" className="tableLink">
              {' '}
              &laquo;Payouts&raquo;.{' '}
            </a>
          Here you&rsquo;ll find all your current and previous payouts with TXID information for each transaction.
        </p>
      </p>

      <p>
        <Title level={5}>I&nbsp;see incoming on&nbsp;LBC, PAX, but it&rsquo;s not confirmed.</Title>
        <p>
          Just wait a&nbsp;little bit. It&rsquo;ll be&nbsp;fully complete after 2-4 confirmations
          on&nbsp;Bitcoin&rsquo;s blockchain&nbsp;
          <a href="https://bitcoin.org/en/how-it-works" className="tableLink" rel="noopener noreferrer" target="_blank">
            (https://bitcoin.org/en/how-it-works).
          </a>
        </p>
      </p>

      <p>
        <Title level={5}>How to&nbsp;change BTC wallet address?</Title>
        <p>
          During Withdraw you can enter any wallet address. Payout Password is&nbsp;required to&nbsp;change wallet
          address.
        </p>
      </p>
      {/* 
    <p>
      <Title level={5}>What is&nbsp;Payout Password?</Title>
      <p>This is&nbsp;a&nbsp;second password which is&nbsp;required to&nbsp;change the BTC wallet address in&nbsp;the Withdraw dialog. You can set or&nbsp;change it&nbsp;on&nbsp;the Settings page. It&nbsp;can&rsquo;t be&nbsp;the same as&nbsp;your main password. If&nbsp;Payout Password is&nbsp;not set it&nbsp;is&nbsp;not possible to&nbsp;change BTC wallet address.</p>
    </p> */}

      <p>
        <Title level={5}>
          I&nbsp;have code in&nbsp;currency which not present on&nbsp;your service. Can I&nbsp;send it&nbsp;to&nbsp;you?
        </Title>
        <p>Yes, just send one code and we&nbsp;will add that currency shortly. You can also contact our support.</p>
      </p>

      <p>
        <Title level={5}>I&nbsp;have codes but don&rsquo;t remember what currency or&nbsp;value they have.</Title>
        <p>It&nbsp;doesn&rsquo;t matter. Our system autodetects values and currencies during processing.</p>
      </p>

      <p>
        <Title level={5}>I&nbsp;have codes but don&rsquo;t remember whether I&rsquo;ve used them or&nbsp;not.</Title>
        <p>
          You can post codes like that. But do&nbsp;not overuse&nbsp;it. We&nbsp;can throttle processing of&nbsp;already
          used/invalid codes.
        </p>
      </p>
      <p>
        <Title level={5}>What&nbsp;is Payout Password?</Title>
        <p>
          This is&nbsp;a second password which is required to change the BTC wallet address in the Withdraw dialog. You can set or change&nbsp;it on the
          
            <a href="/usr_settings" className="tableLink">
              {' '}
              Settings page.{' '}
            </a>
          <br />
          It can&rsquo;t be the same&nbsp;as your main password. If&nbsp;Payout Password is not set it&nbsp;is not possible to change BTC wallet address.
        </p>
      </p>
      <p>
        <Title level={5}>How to change my password?</Title>
        <p>
        Use
            <a href="/usr_settings" className="tableLink">
              change passwords
            </a>
           in Settings (found by clicking on your username on the top right).
        </p>
      </p>

      {/* <p>
      <Title level={5}>How to&nbsp;change my&nbsp;password?</Title>
      <p>Use change password form in&nbsp;Settings (found by&nbsp;clicking on&nbsp;your username on&nbsp;the top right).</p>
    </p> */}
    </section>
    <section className="faq">
      <Title level={2}>Typical errors after code check</Title>

      <p>
        <Title level={5}>
          &laquo;The Steam Wallet code you have entered does not appear to&nbsp;be&nbsp;activated. Please note that
          it&nbsp;may take several hours after time of&nbsp;purchase before activation is&nbsp;completed by&nbsp;your
          retailer. If&nbsp;the problem persists, please return to&nbsp;where the code was purchased for further
          assistance.&raquo;
        </Title>
        <p>
          Contact your retailer and ask them about that problem. They should reactivate that code. After that you can
          post the code again.
        </p>
      </p>

      <p>
        <Title level={5}>
          &laquo;The Steam Wallet code you have entered has already been redeemed. If&nbsp;you have recently purchased
          this code, please contact the seller for further assistance.&raquo;
        </Title>
        <Title level={5}>
          &laquo;The Steam Wallet code you have entered has already been redeemed. Steam Support is&nbsp;unable
          to&nbsp;issue you a&nbsp;new code.&raquo;
        </Title>
        <p>Code has already been used before you&rsquo;ve sent it&nbsp;to&nbsp;our system.</p>
      </p>

      <p>
        <Title level={5}>
          &laquo;The Steam Wallet code you have entered is&nbsp;invalid. Please carefully verify the characters
          as&nbsp;you re-enter the code.&raquo;
        </Title>
        <p>Try to&nbsp;find a&nbsp;mistake in&nbsp;the code and send it&nbsp;again.</p>
      </p>

      <p>
        <Title level={5}>
          &laquo;Unexpected error occurred while trying to&nbsp;add codes, please try again later or&nbsp;contact the
          administrator.&raquo;
        </Title>
        <Title level={5}>&laquo;System error, please contact support.&raquo;</Title>
        <p>Please write to&nbsp;our support.</p>
      </p>
    </section>
  </div>
)

export default FAQ
