import { createAction, createReducer } from '@reduxjs/toolkit'
import frc from 'front-end-common'
import MyCodesService from '../services/NoAuthService'

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools

export const fetchContacts = createAction('contacts', () => ({
  payload: MyCodesService.fetchContactsData(),
}))

const initState = {
  contacts: getDefaultState(),
}

export const noAuthReducer = createReducer(initState, {
  ...getDefaultHandler(fetchContacts, 'contacts'),
})

export default noAuthReducer
