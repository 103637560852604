import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import SubmitCodes from '../submit-codes';
import LandingPage from '../landing';

function RootPage() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  return (
    <div>
      {isAuthenticated ? <SubmitCodes /> : <LandingPage />}
    </div>
  );
}

export default RootPage;
