import ApiService from './ApiService';
import { queryParams } from '../types/api';

const MyCodesService = {
  fetchMyCodes({ filters, ...params }: queryParams) {
    const updatedParams: any = { ...params }

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',')
      }
    }
    return ApiService.get('/user_code_packs', updatedParams)
  },

  fetchCodePack(params: any) {
    return ApiService.get('/codes', params)
  },

  getMultGroups() {
    return ApiService.get('/codes/groups',)
  },
}

export default MyCodesService;
