import React, { useMemo } from 'react'
import CustomImage from '../CustomImage'
import { shallowEqual, useSelector } from 'react-redux'

const WebAlert = () => {

  const webAlertText = useSelector((state) => state?.noAuthReducer?.contacts?.payload?.webAlert?.webAlertText, shallowEqual);
  const webAlertShown = useSelector((state) => state?.noAuthReducer?.contacts?.payload?.webAlert?.webAlertShown, shallowEqual);
  const webAlertBgColor = useSelector((state) => state?.noAuthReducer?.contacts?.payload?.webAlert?.webAlertBgColor, shallowEqual);

  return (
    <>
      {webAlertShown &&
        <div className="notification-container" style={{backgroundColor: webAlertBgColor}}>
                  <span className="notification" dangerouslySetInnerHTML={{ __html: webAlertText }} />
              </div>
}
  </>
  )
}

export default WebAlert;
