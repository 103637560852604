import React, { useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import { MyCodesTableProps } from '../../types/rootState'
import useMediaQuery from '../../utils/useMediaQuery'
import { FilterOutlined } from '@ant-design/icons'
import dataToFilters from '../../utils/dataToFilters'

interface ListType {
  key: string
  id: string
  created: string
  progress: string
  platform: string
  group: string
  valid: number
  invalid: number
  pending: number
  notes: string
  values: string
}

const MyCodesTable: React.FC<MyCodesTableProps> = ({ list, isLoading, pagination, onChange, filters, groupsData }) => {

  const media580 = useMediaQuery('(max-width: 580px)')

  const { payload } = groupsData;


  const groups = payload?.countryCurrencies && dataToFilters(payload?.countryCurrencies, Object.values)
  const platforms = payload?.platformGroups && dataToFilters(payload?.platformGroups, Object.keys)


  function GetColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'created',
        title: 'CREATED',
        align: 'left',
        width: 250,
        sorter: (a, b) => a - b,
        defaultSortOrder: 'ascend',
        render: (date) => 
         
          new Date(+date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          })
      },
      {
        dataIndex: 'progress',
        title: 'PROGRESS',
        align: 'left',
        width: 80,
        render: (progress, record) => {
          return (
            <>
            {progress}
            </>
          )
        }
      },
      {
        dataIndex: 'platform',
        title: 'TYPE',
        align: 'left',
        width: 120,
        filters: platforms,
        filteredValue: filters['platform'] || null,
      },
      {
        dataIndex: 'group',
        title: 'GROUP',
        align: 'left',
        width: 50,
        filters: groups,
        filteredValue: filters['group'] || null,
      },
      {
        dataIndex: 'pending',
        title: 'STATS, # OF CODES',
        align: 'left',
        width: 350,
        filters: [
          {
            text: 'Pending',
            value: 'P',
          },
          {
            text: 'Error',
            value: 'E',
          },
          {
            text: 'Valid',
            value: 'V',
          },
        ],
        filteredValue: filters['pending'] || null,
        filterMultiple: false,
        sorter: (a, b) => a - b, 
        sortDirections: ['ascend', 'descend'],
        render: (pending, record) => {
          return (
              <a href={`/my-codes/${record.id}`} className="tableLink">
                pending: {pending}, valid: {record.valid}, invalid: {record.invalid}
              </a>
          )
        },
      },
      {
        dataIndex: 'notes',
        title: 'NOTES',
        align: 'center',
        width: 100,
      },
      {
        dataIndex: 'values',
        title: 'VALUES',
        align: 'center',
        width: 140,
      },
    ]
  }

  function GetXSMobileColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'created',
        title: 'CREATED',
        align: 'left',
        width: 170,
        sorter: (a, b) => a - b,
        render: (date, record) => {
        return (
          <>
          <span>
          {new Date(+date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          })}
          </span>
          <br />
          {record.progress}
          <br />
          {record.platform}
          <br />
          {record.group}
          </>
          )}
      },
      {
        dataIndex: 'pending',
        title: 'STATS, # OF CODES',
        align: 'left',
        width: 150,
        filters: [
            {
              text: 'Pending',
              value: 'P',
            },
            {
              text: 'Error',
              value: 'E',
            },
            {
              text: 'Valid',
              value: 'V',
            },
          ],
          filteredValue: filters['pending'] || null,
          filterMultiple: false,
        sorter: (a, b) => a - b,
        render: (pending, record) => {
          return (
              <a href={`/my-codes/${record.id}`} className="tableLink">
                pending: {pending}, <br /> valid: {record.valid}, <br /> invalid: {record.invalid}
              </a>
          )
        },
      },
    ]
  }

  const [columns, setColumns] = useState(GetColumns())

  useEffect(() => {
    if (media580) setColumns(GetXSMobileColumns())
    else setColumns(GetColumns())
  }, [list, media580])

  return (
    <Table
      className="myCodesTable"
      rowKey={(record) => record.id}
      dataSource={list}
      columns={columns}
      loading={isLoading}
      pagination={false}
      // scroll={{ y: 'calc(100vh - 125px)', x: 'max-content' }}
      pagination={pagination}
      onChange={(a, b, c) => onChange(a, b, c)}
    />
  )
}

export default MyCodesTable
