import React, { useState } from 'react'
import { Divider, Menu } from 'antd'
import styles from './styles.module.scss'
import UserProfile from '../../UserProfile'
import Balance from '../../Balance'
import UserCurrencySwitcher from '../../UserCurrencySwitcher'

const MobMenu = ({ items, isLoading, setIsLoading, parsedBalance, userName }) => {
  const [menuOpened, setMenuOpened] = useState(false)

  const toggleMenu = () => {
    setMenuOpened((status) => !status)
  }

  // console.log(status)

  return (
    <>
      <div
        className={menuOpened ? `${styles.menuIcon} ${styles.opened}` : styles.menuIcon}
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        role="button"
        // style={status === 'authenticated' ? {marginTop: '18px'} : {marginTop: '8px'}}
        // tabIndex={1}
      >
        <span />
        <span />
        <span />
      </div>
      <div className={!menuOpened ? styles.menuWrapper : `${styles.menuWrapper} ${styles.opened}`}>
      <div
        className={menuOpened ? `${styles.menuIcon} ${styles.opened}` : styles.menuIcon}
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        role="button"
        // style={status === 'authenticated' ? {marginTop: '18px'} : {marginTop: '8px'}}
        // tabIndex={1}
      >
        <span />
        <span />
        <span />
      </div>
      
        <Menu mode="inline" items={items} onClick={toggleMenu} />
        
        <div className={styles.userProfile}>
        {/* <span>{userName}</span> */}
        {/* <UserCurrencySwitcher isLoading={isLoading} setIsLoading={setIsLoading} payload={parsedBalance} /> */}
        <Divider />
        <Balance payload={parsedBalance} isLoading={isLoading} />
        {/* {status !== 'authenticated' && 
        <UserProfile />
        } */}
        </div>
      </div>
    </>
  )
}
export default React.memo(MobMenu)
