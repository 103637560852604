import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createStore from './boots/store';
import App from './boots/App';
import TokenService from '../src/services/TokenService';
import './index.css';
import { authSetIsAuthenticated } from './reducers/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import * as serviceWorker from './serviceWorker'
import CustomImage from './components/CustomImage';
import WebAlert from './components/WebAlert';

const store = createStore({});

if (TokenService.hasToken()) {
  //@ts-ignore
  store.dispatch(authSetIsAuthenticated(true));
}

if (typeof window !== "undefined") {

  const urlParams = new URLSearchParams(window.location.search);

  const utmSource = urlParams.get('utm_source');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmContent = urlParams.get('utm_content');
  const utmTerm = urlParams.get('utm_term');

  const referrer_url = urlParams.get('referrer');

  if (utmSource && !localStorage.getItem('utmSource')) {
    localStorage.setItem('utmSource', utmSource);
}
if (utmCampaign && !localStorage.getItem('utmCampaign')) {
    localStorage.setItem('utmCampaign', utmCampaign);
}
if (utmContent && !localStorage.getItem('utmContent')) {
    localStorage.setItem('utmContent', utmContent);
}
if (utmTerm && !localStorage.getItem('utmTerm')) {
    localStorage.setItem('utmTerm', utmTerm);
}

if (referrer_url && !localStorage.getItem('referrer_url')) {
  localStorage.setItem('referrer_url', referrer_url);
}
}

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
      <div className='App'>
        <Header />
        <WebAlert />
          <App />
        <Footer />
      </div>
    </BrowserRouter>

    </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
