import { Alert, Button, Form, Input, message, Radio, Result, Select, Skeleton, Spin, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
//@ts-ignore
import styles from './styles.module.scss'
import FloatingLabel from '../../FloatingLabel'
import { myBalanceFetch, myPayoutsFetch, myWithdrawalFetch, postNewWithdrawal } from '../../../reducers/user'
import { UserState } from '../../../types/rootState';
import { shallowEqual, useSelector } from 'react-redux'
import { messageError, messageSuccess } from '../../Message'
import { useForm } from 'antd/lib/form/Form'
import PayoutMethodSwtich from '../../PayoutMethodSwitch'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createSelectOptions } from '../../../utils/createSelectOptions'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'


const { Title } = Typography

interface SubmitWithdrawalTypes {
  closeModal: any,
  balanceAmount?: number | string,
  actual: any,
  close: any,
  currency: string
}


const SubmitWithdrawal: React.FC<SubmitWithdrawalTypes> = ({closeModal, currency, balanceAmount, actual, close} ) => {

  const navigate = useNavigate();
  const [form] = useForm();

  const { payload, isLoading } = useSelector((state: UserState) => state?.user?.withdrawal, shallowEqual) || {}


  const commonPayoutInfo = payload && payload.payoutCommon
  const walletAddress = payload && payload.btcAddress
  const payoutPassword = payload && payload.payoutPassSet
  const balance = payload && payload.balanceUsd

  const defaultSelectedPayoutType = commonPayoutInfo ? Object.keys(commonPayoutInfo).includes('UsdToBtc') ? 'UsdToBtc' : Object.keys(commonPayoutInfo)[0] : null

  const [curBtcAddress, setCurBtcAddress] = useState<any>(walletAddress);
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (payload && payload.btcAddress) {
      setCurBtcAddress(payload.btcAddress);
    }
  }, [payload]);

  useEffect(() => {
    if (!walletAddress) {
      dispatch(myWithdrawalFetch())
      //@ts-ignore
        .then((res) => {
          setCurBtcAddress(res.btcAddress);
        })
        //@ts-ignore
        .catch((err) => {
          message.error(`${err}`);
        });
    }
  }, [dispatch, walletAddress]);


  const [isProcessing, setIsProcessing] = useState(false);

  const [amount, setAmount] = useState<any>(null);
  const [password, setPassword] = useState('');
  const [formType, setFormType] = useState(defaultSelectedPayoutType);
  const [payoutData, setPayoutData] = useState(commonPayoutInfo?.[defaultSelectedPayoutType] || {})
  const [bankFormData, setBankFormData] = useState({
    amountCurrency: currency,
    bankName: null,
    bankAccountFirstName: null,
    bankAccountSecondName: null,
    bankAccountNumber: null
  });

  const minimalPayout = () => {
      return currency ==='NGN' ? payoutData?.minPayout * payload?.usdRate : payoutData?.minPayout
  }

  // console.log(`payoutData: ${JSON.stringify(payoutData)}`)

  const [showAlert, setShowAlert] = useState(false);

  const optionsToSelect = createSelectOptions(payoutData?.options)

  // Проверка валидности кошелька
  const regex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
  const isValidAddress = regex.test(curBtcAddress) || formType === 'Bank';

  const usdtRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/
  const isValidUsdtAddress = usdtRegex.test(curBtcAddress) || formType === 'Bank';

  const isAddressValid = (formType === 'UsdToBtc' && regex.test(curBtcAddress)) || (formType === 'ToUsdt' && usdtRegex.test(curBtcAddress)) || formType === 'Bank';

  const checkValidity = () => {
    return amount < minimalPayout() || balance < minimalPayout() || +amount > +actual || !isAddressValid || isProcessing || (payoutPassword && password.length < 1)
  }

  const handleChangeAddress = useCallback(( { target: { value } }:any) => {
    setCurBtcAddress(value)
  }, [])

  const handleChangeAmount = useCallback(( { target: { value } }:any) => {
    const reg = /^\d*(\.\d*)?$/
    if (reg.test(value) || value === '') {
      setAmount(value)
    }
  }, [])

  useEffect(() => {
    if (amount !== null && amount < minimalPayout()) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [formType, amount])

  const handleSuccess =
    () => {
      setIsProcessing(true)
      dispatch(postNewWithdrawal(amount, formType, curBtcAddress, password, bankFormData))
        .then((res: any) => {
          const result = res
          // console.log('result', result)
          result.error && messageError(result.error)
          setIsProcessing(false)
          form.resetFields()
          !result.error && messageSuccess(`Successfully withdrew ${amount} ${currency}`)
          //@ts-ignore
          if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
            //@ts-ignore
            window.fbq('track', 'Purchase');
          }
          dispatch(myBalanceFetch())
          dispatch(myPayoutsFetch())
          setAmount(null)
          closeModal()
          !result.error && navigate(`/payouts/`)
        })
        .catch((error: any) => {
          // console.log('error', error)
          if (error.status === 403)  messageError(`Action is prohibited!`)
          else messageError(`Failed to withdraw. ${error}`)
          setIsProcessing(false)
        })

      }

      const handleClick = () => {
        setAmount(actual);
      };

      const handleChangeForm = useCallback((e: any) => {
        form.resetFields()
        setFormType(e.target.value);

        setPayoutData(commonPayoutInfo[e.target.value])

        // const payoutElement = data.find((el) => el.payoutType === e.target.value)
        setBankFormData({
          amountCurrency: currency,
          bankName: null,
          bankAccountFirstName: null,
          bankAccountSecondName: null,
          bankAccountNumber: null
        });
        setCurBtcAddress(e.target.value === 'UsdToBtc' ? walletAddress : null);
        setAmount(null);
      }, [])

      const available = () => {
        return balanceAmount
      };


      // Отрендерить форму на основе выбранного средства платежа
      const renderForm = () => {
        if (formType === 'UsdToBtc') {
          return (
            <>
              <Form.Item>
                <FloatingLabel
                label="BTC Wallet"
                placeholder="BTC Wallet"
                name="curBtcAddress"
                value={curBtcAddress}
                onChange={handleChangeAddress}
                className={styles.input}
                required={true}
                isValidAddress={isValidAddress}
                // style={{fontWeight: 'bold'}}
                />

              </Form.Item>
            </>
          );
        } else if (formType === 'Bank') {
          return (
            <>
             <Form.Item
        name="bankAccountFirstName"
        rules={[
          {
            required: true,
            message:  <span style={{ color: 'red' }}>Please enter your First Name</span>,
          },
        ]}
        shouldUpdate
        >
        <FloatingLabel
        label="First Name"
        placeholder="First Name"
        name="first name"
        value={bankFormData.bankAccountFirstName}
        onChange={(e: any) =>
          setBankFormData((prev) => ({
            ...prev,
            bankAccountFirstName: e.target.value
          }))
        }
        className={styles.input}
        />
        </Form.Item>
        <Form.Item
        name="bankAccountLastName"
        rules={[
          {
            required: true,
            message:  <span style={{ color: 'red' }}>Please enter your Last Name</span>,
          },
        ]}
        shouldUpdate
        >
           <FloatingLabel
        label="Last name"
        placeholder="Last name"
        name="last name"
        value={bankFormData.bankAccountSecondName}
        onChange={(e: any) =>
          setBankFormData((prev) => ({
            ...prev,
            bankAccountSecondName: e.target.value
          }))
        }
        className={styles.input}
        />
        </Form.Item>
        <Form.Item
        name="bankName"
        rules={[
          {
            required: true,
            message:  <span style={{ color: 'red' }}>Please select bank</span>,
          },
        ]}
        shouldUpdate
        >
        <Select
        placeholder="Select your bank"
        className={styles.selectInput}
        onChange={(value) =>
          setBankFormData((prev) => ({
            ...prev,
            bankName: value
          }))
        }
        options={optionsToSelect}
        />
        </Form.Item>
        <Form.Item
        name="bankAccount"
        rules={[
          {
            required: true,
            message:  <span style={{ color: 'red' }}>Please enter your bank account number</span>,
          },
        ]}
        shouldUpdate>
          <FloatingLabel
        label="Your bank account"
        placeholder="Your bank account"
        name="bank account"
        value={bankFormData.bankAccountNumber}
        onChange={(e: any) =>
          setBankFormData((prev) => ({
            ...prev,
            bankAccountNumber: e.target.value
          }))
        }
        className={styles.input}
        />
        </Form.Item>
            </>
          );
        } else if (formType === 'ToUsdt') {
          return (
            <>
              <Form.Item>
                <FloatingLabel
                label="USDT Wallet"
                placeholder="USDT Wallet"
                name="curBtcAddress"
                value={curBtcAddress}
                onChange={handleChangeAddress}
                className={styles.input}
                required={true}
                isValidAddress={isValidUsdtAddress}
                // style={{fontWeight: 'bold'}}
                />

              </Form.Item>
            </>
          );
        }
    
        return null;
      };

  return (
    <div>

    <Title>Withdrawal</Title>
    {isLoading ? <div style={{height: '350px'}}>
    <Skeleton.Input size="small" active />
    <br/>
    <br/>
    <Skeleton.Input size="large" active block={true} />
    <br/>
    <br/>
    <br/>
    <br/>
    <Skeleton.Input size="large" active/>
    <br/>
    <br/>
    <br/>
    <br/>
    <Skeleton.Input size="large" active/>
    </div> :
    commonPayoutInfo ? 
      <>
      <PayoutMethodSwtich commonPayoutInfo={commonPayoutInfo} handleChangeForm={handleChangeForm} formType={formType} />
  <Form
    form={form}
    onFinish={handleSuccess}
    >
      {renderForm()}
      <Form.Item>
      
      <Input.Password
        placeholder={payoutPassword ? 'Enter payout password' : 'For better security set payout password in settings'}
        value={password}
        onChange={e => setPassword(e.target.value)}
        style={{fontWeight: 'bold'}}
        className={styles.input}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        disabled={!payoutPassword}
        />
        {!payoutPassword && <a href="/usr-settings">Set payout password {'->'}</a>}
      </Form.Item>
    
    {/* <br /> */}
    <span className={styles.text}>Available: <span style={{color: 'rgba(15, 138, 15, 1)'}}>{available()}</span></span>
      <Form.Item>
            <div className={styles.valueInput}>
          <FloatingLabel
            label={`${currency === 'NGN' ? '₦' : '$'} value`}
            placeholder={`${currency === 'NGN' ? '₦' : '$'} value`}
            name="amount"
            value={amount}
            onChange={handleChangeAmount}
            minimalPayout={minimalPayout()}
            // style={{fontWeight: 'bold'}}
            isValueInput={true}
            />
            <div className={styles.currencyRate}>
              <span>₿ {(amount / payload?.usdRate / payload?.rate).toFixed(5)}</span>
              {currency === 'NGN'  && <span>$ {(amount / payload?.usdRate).toFixed(2)}</span>}
            </div>
            </div>
        <span onClick={handleClick} className={styles.textBlue}>Set Max Value</span>
        
        <br/>
        <small className={styles.textGrey}>{payoutData?.withdrawalText}</small>
        {showAlert &&
          <Alert
          type="error"
          showIcon
          message={`Minimum payout amount is ${minimalPayout().toFixed(2)} ${currency === 'NGN' ? '₦' : '$'}`}
          banner
          />
          }

          {+balance < +minimalPayout() &&
              <Alert
              style={{marginTop: '8px'}}
              type="error"
              showIcon
              message={`You don't have enough amount to withdraw`}
              banner
              />
            }
      </Form.Item>
    
    <br />
    <Form.Item>
        <Button
        aria-label="Withdraw money button"
        htmlType="submit"
        type="primary"
        className={styles.button}
        disabled={checkValidity()}
        >
          {isProcessing ? `Submitting...` : 'Submit'}
          </Button>
      </Form.Item>
    </Form>
    <div className={styles.closeModal} onClick={close}>Close</div>
    </>
    : <Result
    status="warning"
    subTitle={`Sorry, currently none of payout methods is available. Please, contact us, if you have any questions!`}
  />
    }

    </div>
  )
}

export default SubmitWithdrawal
