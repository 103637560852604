import React, { useEffect, useState } from 'react';
import Routes from '../Routes';
import { isClapswapDomain } from '../../utils/extractDomain';
import { loadScript, loadYandexMetrika } from '../../utils/loadScript';

function App() {

  const [ymId, setYmId] = useState(null)

  useEffect(() => {
    const isClapswap = isClapswapDomain();
    
    if (isClapswap === true) {
      // Загрузить гугл аналитику 
      loadScript('https://www.googletagmanager.com/gtag/js?id=G-ZCTJZ46ZXP');
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-ZCTJZ46ZXP');
      // Загрузить яндекс метрику
      setYmId(98023988)
  
    } else if (isClapswap === false) {
      // Загрузить гугл аналитику 
      loadScript('https://www.googletagmanager.com/gtag/js?id=G-PJ8WTHGHDT');
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-PJ8WTHGHDT');
      // Загрузить яндекс метрику
      setYmId(98023972)
    }
  }, []);

  return (
  <div style={{margin: '12px 0 5vh 0'}}>
    <div dangerouslySetInnerHTML={{__html: loadYandexMetrika(ymId)}}/>
    <Routes />
  </div>
  )
}

export default App;
