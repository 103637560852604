import React, { useCallback, useEffect, useMemo, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
// import { useSession } from 'next-auth/react'
import { Skeleton, Spin } from 'antd'
// import { myBalanceFetch } from '../../src/reducers/user'
// import { UserState } from '../../types/rootState'

function Balance({ payload, isLoading }) {
  const dispatch = useDispatch();
  const [debounceBalance, setDebounceBalance] = useState(false);

  // payload && JSON.parse(payload)
  // console.log('PAYLOADBAL', payload)

  useEffect(() => {
    if (isLoading) {
      setDebounceBalance(true)
      setTimeout(() => {
        setDebounceBalance(false)
      }, 4000)
    }
  }, [isLoading])

  useEffect(() => {})

  const { actual, pending, currency } = useMemo(
    () => ({
      actual: (payload && payload.actual) || '',
      pending: (payload && payload.pending) || '',
      currency: (payload && payload.currency) || 'USD',
    }),
    [payload]
  )

  const balanceAmount = useMemo(
    () =>
      new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(+actual),
    [actual, currency]
  )
  const pendingAmount = useMemo(
    () =>
      new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(+pending),
    [pending, currency]
  )

  return (
    <>
        <div className={styles.data}>
          <div className={styles.dataItem}>
            <a href="/wallet" className={styles.balanceText}>Available</a>
            <span className={styles.balanceAmount}>
            {/* {balanceAmount} */}
              {isLoading ? <Skeleton.Input style={{height: '12px'}} active size='small' /> : balanceAmount}
              {/* {isLoading ? <Spin size="small" style={{ margin: '-2px 5px 0' }} /> : '₦ 579442,78'} */}
            </span>
          </div>
          <div className={styles.dataItem}>
            <a href="/my-codes?filters[pending][]=P" className={styles.balanceText}>Pending</a>
            <span className={styles.pendingAmount}>
              {isLoading ? <Skeleton.Input style={{height: '12px'}} active size='small' /> : pendingAmount}
              {/* {pendingAmount} */}
              {/* {isLoading ? <Spin size="small" style={{ margin: '-2px 5px 0' }} /> : '₦ 579442,78'} */}
            </span>
          </div>
        </div>
  
  </>
  )
}

export default Balance
